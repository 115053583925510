.room-modal-pin-message {
    position: relative;
    width: 100% !important;
    max-height: calc(100% - 58px) !important;
    padding: 0 !important;
    
    .room-modal-pin-message-inner-wrapper {
        overflow-y: auto !important;
        max-height: calc(100% - 56px);
        position: absolute;
        bottom: 56px;
        width: 100%;
        left: 0;
    }

    .unpin-all-message-wrapper {
        height: 56px;
        width: 100%;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #ff7d00;
        text-align: center;
        padding: 17px 20px 0;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            position: relative;
            margin-right: 4px;
            top: -2px;
        }
    }

    .room-modal-pin-message-wrapper {
        width: 100%;
        // height: 1px;
        position: relative;
        padding: 0 0 16px;
        border-bottom: solid 1px #dcdcdc;
        // text-align: center;
        
        // div {
        //     border-top: solid 1px #dcdcdc;
        //     display: inline-block;
        //     width: 100%;
        // }
    }

    .no-pinned-message {
        width: 100%;
        text-align: center;
        margin-top: 100px;

        .no-pinned-message-img {
            margin: 44px 0 26px;
        }

        .no-pinned-title {
            margin: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .no-pinned-desc {
            margin: 12px 0 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
        }
    }

    .message-action-wrapper-new {
        display: none !important;
    }

    .loading-message-wrapper {
        padding-top: 16px;
        text-align: center;
        width: 100%;

        .lds-ring {
            div {
                border-color: #ff7d00 transparent transparent transparent;
            }
        }
    }
}