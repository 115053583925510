.room-data-video-wrapper {
    position: relative;
    display: inline-block;
    width: calc((100% / 3) - (8px / 3));
    // height: 135px;
    height: 0;
    padding-top: calc((100% / 3) - (8px / 3)); // 1:1 aspect ratio
    margin: 0 2px 4px 0;
    vertical-align: top;
    border-radius: 4px;
    background: rgba(25, 25, 25, 0.5);
    // background: pink;
    // overflow: hidden;
     
    &:nth-child(3n) {
        margin-right: 0;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .video-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        left: 6px;
        bottom: 6px;
        font-size: 20px;
        z-index: 1;
        color: #ffffff;
    }

    .size-wrapper {
        font-size: 12px;
        position: absolute;
        right: 4px;
        bottom: 4px;
        line-height: 1;
        letter-spacing: -0.4px;
        color: #ffffff;
        background: rgba(25, 25, 25, 0.6);
        padding: 4px 6px 4px;
        border-radius: 20px;
    }

    .duration-wrapper {
        font-size: 12px;
        position: absolute;
        right: 4px;
        top: 4px;
        line-height: 1;
        letter-spacing: -0.4px;
        color: #ffffff;
        background: rgba(25, 25, 25, 0.6);
        padding: 4px 6px 4px;
        border-radius: 20px;
    }
    
    .action-icon-wrapper {
        background: rgba(25, 25, 25, 0.4);
        border-radius: 24px;
        box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
        height: 36px;
        width: 36px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 3px;
        cursor: pointer;

        .icon-action {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: 18px;
        }
    }

    .video-thumbnail {
        width: 100%;
        border-radius: 4px;
    }

    video {
        cursor: pointer;
    }

    .CircularProgressbar .CircularProgressbar-trail {
        stroke: transparent;
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: #ffffff;
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        height: 180px;

        @media screen and (max-width: 450px) {
            height: 95px;
        }
    }

    .hover-dropdown-menu-wrapper {
        position: absolute;
        display: none;
        top: 20px;
        right: 8px;
        z-index: 2;
        width: 24px;
        height: 24px;
    }

    .hover-dropdown-menu-wrapper .dropdown .dropdown-menu .dropdown-item .action-icon {
        top: 12px;
        left: 12px;
    }
}

.room-data-video-wrapper:hover {

    .hover-dropdown-menu-wrapper {
        display: block !important;
    }
}
