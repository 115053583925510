.single-date-modal-calendar {
    font-family: 'PT Root UI';
    font-style: bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919;

    .modal-content {
        width: 460px;
        padding: 8px;
        position: relative;
    }

    .date-time-picker-calendar-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .modal-date-time-picker-icon {
        display: flex;
        width: 28px;
        height: 28px;
        margin-top: 5px;
        margin-right: 8px;
        color: var(--main-dark-orange);
        position: relative;
    }

    .custom-date-picker {
        display: flex;
        position: relative;
    }

    .rdp {
        margin: 0;
        --rdp-background-color: #ff7e0033;

        svg {
            color: var(--main-dark-orange);
        }

        .rdp-day {
            margin: 4px;
        }

        .rdp-day_selected {
            color: white;
            opacity: 1;
            background-color: var(--main-dark-orange);
        }

        .rdp-button[disabled]:not(.rdp-day_selected) {
            opacity: 0.2;
            color: #191919 !important;
            background-image: none !important;
        }
    }

    .time-input {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        margin-top: 16px;
        justify-content: center;
    
        input {
            width: 60px;
            padding: 4px 4px 4px 16px;
            border: 1px solid rgba(25, 25, 25, 0.2);
            border-radius: 8px;
            font-family: 'PT Root UI';
            font-style: bold;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.6px;
            text-align: center;
            color: #191919;
        }

        p {
            margin: 8px;
        }
    }

    .modal-footer {
        position: relative;
        border-top: 0;

        button {
            width: 100%;
            height: 44px;
            margin: 0px 8px 0px 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
        }
    }
}
