.room-modal-group-in-common-message {
    position: relative;
    width: 100% !important;
    max-height: calc(100% - 58px) !important;
    overflow-y: auto !important;
    padding: 0 0 20px !important;

    .room-modal-group-in-common-message-wrapper {
        width: 100%;
        height: 1px;
        position: relative;
        padding: 0 24px 16px;
        text-align: center;
        
        div {
            border-top: solid 1px #dcdcdc;
            display: inline-block;
            width: 100%;
        }
    }

    .no-group-in-common-message {
        width: 100%;
        text-align: center;

        .no-group-in-common-message-img {
            margin: 100px 0 26px;
        }

        .no-group-in-common-title {
            margin: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .no-group-in-common-desc {
            margin: 12px 0 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
        }
    }

    .group-in-common-counter {
        padding: 6px 12px;
        line-height: 1;
        font-size: 10px;
        letter-spacing: 1.5px;
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.05);
    }

    .group-in-common-list-wrapper {
        .group-in-common-list {
            cursor: pointer;
            background: #fff;
            width: 100%;
            display: block;
            position: relative;
            padding: 0 0 0 74px;
            height: 64px;
            border-bottom: solid 1px rgba(25, 25, 25, 0.1); 
    
            .room-info-avatar {
                width: 52px;
                height: 52px;
                border-radius: 50%;
                position: absolute;
                left: 12px;
                top: 6px;

                .mini-group-icon {
                    width: 18px;
                    height: 18px;
                    bottom: 0 !important;
                    top: unset !important;
                    right: 0 !important;
                    left: unset !important;
                }
    
                .room-info-avatar-image {
                    border-radius: 50%;
                    width: 52px;
                    height: 52px;
                    display: inline-block;
                }
    
                .user-avatar-name {
                    border-radius: 50%;
                    width: 52px;
                    height: 52px;
                    display: inline-block;
                    text-align: center;
                    font-size: 20px;
                    letter-spacing: -0.6px;
                    color: white;
                    line-height: 52px;
                    text-transform: uppercase;
                    margin: unset !important;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
    
            .message-info-main {
                height: 64px;
                width: 100%;
                padding: 0 150px 0 0;
                display: inline-block;
                position: relative;
                // border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    
                .user-name {
                    font-size: 16px;
                    letter-spacing: -0.5px;
                    color: #191919;
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 22px 0 0 0;
                }
            }
        }
    }

    .message-action-wrapper-new {
        display: none !important;
    }

    .loading-message-wrapper {
        padding-top: 16px;
        text-align: center;
        width: 100%;

        .lds-ring {
            div {
                border-color: #ff7d00 transparent transparent transparent;
            }
        }
    }
}