.blocked-contacts-modal {
    .modal-header {
        background: #ffffff;
        position: relative;
        text-align: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 15px;
        // height: 53px;
        height: auto;
        overflow: hidden;
        // width: calc(100% - 17px);
        width: calc(100%);

        .close-blocked-contacts {
            font-size: 30px;
            position: absolute;
            top: 214px;
            right: 5%;
            cursor: pointer;
            transition: ease-in-out .2s;

            &:hover {
                color: #ff7d00;
            }
        }

        .modal-title {
            margin: 0;
            position: relative;
            width: 100%;
        }

        .blocked-contacts-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
            line-height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-top-title {
            height: auto;
            width: 100%;
            overflow: hidden;
            display: block;
            text-align: center;
            padding: 0 40px;
            white-space: nowrap;
            // justify-content: space-between;
            // align-items: center;

            p {
                margin: 0 !important;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            svg {
                width: 32px;
                height: 32px;
                cursor: pointer;
                color: var(--main-orange);
                // flex-shrink: 0;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);

                &:hover {
                    color: var(--main-darker-orange);
                }

                &.close-modal-account-button {
                    right: 0;
                    left: unset !important;
                }
            }

            .edit-mode-button {
                margin: 0;
                color: var(--main-orange);
                cursor: pointer;
                font-family: "PTRootUI", sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.5px;
                margin-left: 5px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(0, -50%);

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }
    }

    .modal-content {
        width: 435px;
        border-radius: 8px;
        max-height: calc(100vh - 40px);
        height: 100%;
        overflow: hidden;
        background-color: #f3f3f3;

        .modal-room-info-content {
            left: -500px;
            transition: ease-in-out .3s;
            position: relative;
            margin: 24px 0 0px;
            overflow: hidden;

            &.active-modal-room-info-content {
                left: 0;
            }

            &.active-circle-modal-room-info {
                left: 0;
                margin: 10px 0;
            }
        }
    }

    .modal-body {
        background: #f3f3f3;
        padding: 0px 0px 66px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 93px);
        width: calc(100% + 17px);

        .loading-contact-wrapper {
            padding: 24px;
            text-align: center;

            .lds-ring {
                width: 34px;
                height: 34px;

                div {
                    width: 34px;
                    height: 34px;
                    border: solid 3px;
                    border-color: #ff7d00 transparent transparent transparent;
                }
            }
        }

        #change-avatar-input {
            display: none;
        }

        .blocked-contacts-avatar-wrapper {
            width: 100%;
            position: relative;
            text-align: center;
            padding: 0;
        }

        .blocked-contacts-avatar {
            width: 100%;
            // background: #aff2c0;
            color: white;
            display: inline-block;
            line-height: 80px;
            font-size: 80px;

            .no-avatar-placeholder-wrapper {
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                .no-avatar-placeholder {
                    width: 105px;
                    position: absolute;
                    margin-top: -40px;
                    top: 50%;
                    left: 50%;
                    margin-left: -52.5px;
                    text-transform: uppercase;
                }
            }
        }

        .no-blocked-contact-container {
            position: fixed;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            padding: 0px 60px 0px;
        
            .no-blocked-contact-desc {
                margin: 12px 0 0;
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.3px;
            }
        }
    }

    .modal-footer {
        background: #ffffff;
        border-top: solid 1px #dcdcdc;
        padding: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        button {
            width: 100%;
            padding: 15px 0;
            font-size: 16px;
            letter-spacing: -0.3px;
            line-height: 1;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        .modal-content {
            width: 100%;
            height: 100%;
            max-height: unset;
            border-radius: 0;

            .modal-body {
                width: 100%;
                max-height: unset;
                height: calc(100vh - 53px);
            }

            .circle-top-background {
                // width: 120vw;
                // left: 50%;
                // transform: translate(-50%, 0);
                // top: -205px;
            }
        }
    }

    .blocked-contact-wrapper {
        position: relative;
        width: 100%;
        height: 68px;
        padding: 10px 16px 6px;
        background: #ffffff;
        border-bottom: solid 0.5px rgba(25, 25, 25, 0.1);
        cursor: pointer;
    
        p {
            margin-top: 16px;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    
        .blocked-contact-avatar-wrapper {
            position: relative;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            color: #ffffff;
            background: #aff2c0;
            line-height: 52px;
            text-align: center;
            display: inline-block;
        }
    
        img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            object-fit: cover;
        }
    
        .blocked-contact-name {
            position: relative;
            display: inline-block;
            width: calc(100% - 100px);
            height:100%;
            line-height: 100%;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
    
            &.is-editing {
                width: calc(100% - 132px);
            }
    
            b {
                white-space: nowrap;
                text-overflow: ellipsis;
            }
    
            &:hover {
                color: var(--main-orange);
            }
        }
        
        .unblock-icon {
            position: relative;
            display: none;
            margin-left: 16px;
            height: 100%;
            transform: translate(0, -13px);
    
            &.is-editing {
                display: inline-block;
            }
    
            svg {
                width: 32px;
                height: 32px;
                margin-top: 8px;
                color: #FF3F57;
            }
        }
    }
}
