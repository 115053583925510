.setup-room-list-modal {
    background: #4e4e4e;
    
    .modal-content {
        width: 376px;
        display: inline-block;
        border-radius: 8px;
        padding: 32px;
        text-align: center;

        .setup-main-content {
            .setup-image-content {
                height: 106px;
                width: 106px;
                display: inline-block;
                position: relative;
                border-radius: 50%;
                margin-bottom: 12px;

                .lds-ring {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 106px;
                    width: 106px;

                    div {
                        height: 106px;
                        width: 106px;
                        border: 12px solid;
                        border-color: #ff7d00 transparent transparent transparent
                    }
                }

                &.setup-success {
                    border: 12px solid #19C700;
                }

                &.setup-fail {
                    border: 12px solid #FF3F57;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            b {
                font-size: 20px;
                letter-spacing: -0.6px;
            }

            p {
                font-size: 16px;
                letter-spacing: -0.3px;
                margin: 8px 0 0;

                &.retry-setup {
                    color: #ff7d00;
                    transform: ease-in-out .1s;
                    cursor: pointer;

                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
                
                svg {
                    font-size: 20px;
                    margin-right: 7px;
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                }
            }
        }

        @media screen and (min-width: 300px) and (max-width: 1024px) {
            width: 100%;
            height: 100%;
            border-radius: 0;

            .modal-body {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }
    }
}