.room-data-document-wrapper {
    position: relative;
    width: 100%;
    height: 56px;
    padding: 8px 0px 8px;
    cursor: pointer;

    .room-data-document-action-icon-wrapper {
        display: inline-block;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 16px;
        border-radius: 8px;
        background-color: var(--main-dark-orange);
    
        .room-data-document-action-icon {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
        }

        .room-data-document-action-icon-downloading {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
        }
    }

    .room-data-document-label-wrapper {
        display: block;
        position: absolute;
        width: calc(100% - 80px);
        max-height: 40px;
        margin-left: 8px;
        right: 16px;
        bottom: 8px;

        .room-data-document-filename {
            width: 100%;
            height: 24px;
            margin-top: -2px;
            font-style: bold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            float:left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #191919;
        }

        .room-data-document-info {
            width: 100%;
            height: 16px;
            margin-top: 4px;
            font-style: normal;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: -0.2px;
            float:left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(25, 25, 25, 0.8);
        }
    }

    .room-data-document-separator {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 1px;
        background-color: #E8E8E8;
    }

    .room-data-document-clickable {
        position:absolute; 
        width: calc(100% - 40px);
        height: 100%;
        top: 0px;
        left: 0px;
        right: 40px;
        z-index: 1;
    }

    .CircularProgressbar {
        padding: 4px;

        .CircularProgressbar-trail {
            stroke: transparent;
        }

        .CircularProgressbar-path {
            stroke: #ffffff;
        }
    }

    .hover-dropdown-menu-wrapper {
        position: absolute;
        display: none;
        top: 50%;
        transform: translate(0, -50%);
        right: 16px;
        z-index: 2;
        width: 24px;
        height: 24px;
    }

    .hover-dropdown-menu-wrapper .dropdown .dropdown-menu .dropdown-item .action-icon {
        top: 0px;
        left: 0px;
    }
}

.room-data-document-wrapper:hover {

    .room-data-document-label-wrapper {
        width: calc(100% - 112px);
        right: 48px;
    }

    .hover-dropdown-menu-wrapper {
        display: block !important;
    }
}
