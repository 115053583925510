.hover-dropdown-menu-wrapper {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
    z-index: 2;
    width: 24px;
    height: 24px;
    // display: none;

    &.hover-dropdown-menu-wrapper-arrow-right {
      right: -80px !important;
      top: 48% !important;
      z-index: -1px;
    }

    &.hover-dropdown-menu-wrapper-in {
      left: unset;
      right: -40px;
    }
          
    button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      padding: 0;
      background: none;
      border: 0;
      // background: rgba(25, 25, 25, 0.05) !important;
      background: #f4f4f4 !important;
      box-shadow: none !important;
      position: relative;
 
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        color: rgba(25, 25, 25, 0.8);
      }

      &:hover {
        // background: rgba(25, 25, 25, 0.1) !important;
        background: #e9e9e9 !important;
      }
    }

    .dropdown {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      .dropdown-menu {
        z-index: 3;
        box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
        border: 0;
        border-radius: 6px;
        padding: 0;
        overflow: hidden;
        width: 190px;

        .dropdown-item {
          padding: 0 16px;
          width: 100%;
          height: 40px;
          font-size: 15px;
          color: rgba(25, 25, 25, 1);
          letter-spacing: -0.3px;
          background: #ffffff !important;
          border-radius: 0;
          
          .action-icon {
            position: relative;
            top: 12px;
            left: 12px;
            width: 24px;
            height: 24px;

            &.action-white {
                display: none;
            }
          }

          &:hover {
            background: #f3f3f3 !important;
            // color: #fff;

            // .action-white {
            //     display: inline-block;
            // }

            // .action-orange {
            //     display: none;
            // }
          }

          svg {
            margin-right: 8px;
            color: #ff7d00;
            font-size: 20px;
            position: relative;
            top: 2px;

            &.icon-delete {
              color: #ff3f57;
            }
          }

          img {
            margin-right: 8px;
            position: relative;
            top: 6px;
          }
        }
      }
    }
  }
  