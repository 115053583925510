.ToastContainer {
    width: auto !important;
    font-size: 16px;
  }
  
  .Toastify__toast {
    min-height: 0 !important;
    // max-width: 300px;
    margin-bottom: 7px !important;
    box-shadow: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;

    svg, img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }
  
  .Toastify__toast-body {
    margin: 0;
    padding: 0 16px 0 8px !important;
  }
  
  .ToastContainer .ToastContent {
    /* background: #7EC82A !important; */
    background: #191919;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 3px 16px !important;
    width: auto !important;
  }
  
  .ToastContainer .ToastContent svg {
    position: relative;
    top: -2px;
  }
  
  .ToastContainer .ToastContent-fail {
    // background: #ff3f57 !important;
    background: #FFECEE !important;
    // color: #fff !important;
    color: #FF3F57 !important;
    border-radius: 8px !important;
    border: 1px solid #FF3F57 !important;
    // padding: 12px 16px !important;
    // width: auto !important;
    // font-size: 16px;
    // font-weight: bold;
    // line-height: 24px;
    // letter-spacing: -0.5px;
  }
  
  .ToastContainer .ToastContent button,  .ToastContainer .ToastContent-fail button{
    display: none;
  }
  
  .ToastContainer .ToastContent .Toastify__progress-bar {
    background: #fff !important;
  }