.room-list-chat-container {
    // box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
    max-height: calc(100% - 52px);
    overflow-y: hidden;
    height: 100%;
    background: #ffffff;

    .dialog-message-wrapper {
        width: calc(100% - 80px);
        display: inline-block;
        position: relative;
        // border-bottom: solid 1px #dcdcdc;
        padding: 1px 0 9px 0;
        top: 4px;
        box-shadow: 0 1px #dcdcdc;

        .dialog-top {
            position: relative;
            width: 100%;
            white-space: nowrap;
            padding-right: 90px;
            height: 24px;
            
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 4px);
                display: inline-block;
            }

            .room-list-mute-icon {
                // position: absolute;
                // top: 4px;
                // right: 74px;
                position: relative;
                top: 4px;
                margin-left: 4px;
            }

            .room-list-pin-icon {

            }

            .dialog-date {
                color: rgba(25, 25, 25, 0.6);
                font-size: 12px;
                letter-spacing: -0.4px;
                line-height: 18px;
                position: absolute;
                right: 16px;
                top: 0;
            }
        }

        .dialog-bottom {
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 21px;
            padding: 0 95px 0 0;
            position: relative;
            // min-height: 32px;

            span {
                color: #191919;
            }

            p {
                margin: 0;
                white-space: nowrap;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }

            .message-status {
                position: absolute;
                top: 4px;
                right: 16px;
                width: 73px;
                text-align: right;
                height: 21px;

                .unread-count-wrapper, .mention-badge {
                    background: #ff7d00;
                    color: #ffffff;
                    font-size: 10px;
                    border-radius: 50%;
                    height: 24px;
                    min-width: 24px;
                    text-align: center;
                    line-height: 24px;
                    position: relative;
                    top: -4px;
                    padding: 0 5px;
                    margin-left: 4px;
                    display: inline-block;
                    vertical-align: top;
                }

                img {
                    margin-left: 4px;
                }
            }
        }
    }

    .room-list-loading-wrapper {
        width: 100%;
        padding-top: 20px;
        text-align: center;

        .lds-ring {
            div {
                border-color: #ff7d00 transparent transparent transparent;
            }
        }

        b {
            color: #ff7d00;
        }
    }

    .chat-list-wrapper {
        background: #ffffff;
        width: 100%;
        // height: 82px;
        height: 68px;
        padding: 8px 0;
        cursor: pointer;
        position: relative;

        .room-list-click-area {
            position: absolute;
            display: inline-block;
            height: 100%;
            width: calc(100% - 35px);
            top: 0;
            left: 0;
            z-index: 1;
        }
        
        .show-room-list-action {
           display: none;
           font-size: 20px;
        }

        .dropdown {
            z-index: 2;
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 21px;

            .dropdown-menu {
                background: #fff;
                border-radius: 8px;
                width: 180px;
                overflow: hidden;
                z-index: 1;
                top: 4px;
                right: 10px;
                padding: 0;
                box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

                .dropdown-item {
                    position: relative;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    padding: 15px 14px 15px 44px;
                    border: 0 !important;
                    
                    svg, img {
                        position: absolute;
                        left: 14px;
                        top: 50%;
                        transform: translate(0, -50%);
                        color: #ff7d00;
                        font-size: 20px;
                        width: 20px;
                    }

                    &:hover {
                        background: #E8E8E8;
                        // color: #fff !important;
    
                        svg {
                            color: #fff;
                        }
                    }
                }
            }

            
            .toggle-dropdown {
                padding: 0;
                background: transparent;
                color: rgba(25, 25, 25, 0.6) !important;
                border: 0 !important;
                box-shadow: none !important;
            }
        }
        
        &:hover {
            // &::before {
            //     position: absolute;
            //     top: 4px;
            //     left: 4px;
            //     border-radius: 8px;
            //     background: #FFE7D0;
            //     width: calc(100% - 8px);
            //     height: calc(100% - 8px);
            //     content: "";
            // }
            background: #E8E8E8;

            .dialog-message-wrapper {
                box-shadow: none;
            }

            // .chat-list-action-wrapper {
            //     display: block;
            // }
            .mention-badge {
                display: none !important;
            }

            .unread-count-wrapper {
                display: none !important;
            }

            .icon-chat-status {
                display: none !important;
            }

            .room-list-pin-icon {
                display: none !important;
            }

            .show-room-list-action {
                display: block;
            }
        }

        &.active-chat-list {
            background: #ff7e00;
            color: #ffffff;

            .dialog-message-wrapper {
                box-shadow: none;
            }

            .dialog-bottom {
                color: #ffffff;

                span {
                    color: #ffffff;
                }
            }

            .dialog-date {
                color: #ffffff;
            }

            .unread-count-wrapper, .mention-badge {
                background: #ffffff !important;
                color: #ff7d00 !important;
            }
        }

        .chat-avatar-wrapper {
            width: 80px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            position: relative;
            // top: 4px;

            img {
                width: 52px;
                height: 52px;
                display: inline-block;
                border-radius: 50%;
                object-fit: cover;
                
                &.group-badge {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    bottom: 1px;
                    right: 11px;
                }
            }

            .user-avatar-name {
                border-radius: 50%;
                width: 52px;
                height: 52px;
                display: inline-block;
                text-align: center;
                font-size: 20px;
                letter-spacing: -0.6px;
                color: white;
                line-height: 52px;
                text-transform: uppercase;
            }
        }

        &.chat-list-wrapper-saved-message {
            // background: var(--main-dark-orange);
            // color: #fff;

            // &.active-chat-list {
            //     background: var(--main-darker-orange);
            // }

            .dialog-date {
                font-size: 12px;
                letter-spacing: -0.4px;
                line-height: 18px;
                position: absolute;
                right: 16px;
                top: 0;
                // color: #fff;
                position: absolute;
                top: 8px;
            }

            .chat-avatar-wrapper {
                .chat-avatar-circle-wrapper {
                    width: 52px;
                    height: 52px;
                    background: var(--main-dark-orange);
                    // border: solid 2px #fff;
                    border-radius: 50%;
                    display: inline-block;
                    border: solid 2px #fff;

                    svg {
                        position: absolute;
                        top: 10px;
                        left: 50%; 
                        transform: translate(-50%, 0);
                        font-size: 32px;
                        color: #fff;
                    }
                }
            }

            .dialog-message-wrapper {
                // box-shadow: none !important;
                // position: absolute;
                // left: 80px;
                // top: 50%;
                // padding: 0;
                // transform: translate(0, -50%);

                .dialog-bottom {
                    // color: #fff;
                }
            }

            .dialog-date {
                // color: #fff;
            }

            &:hover {
                // background: var(--main-darker-orange);
            }
        }

        // &:nth-last-child(1) {
        //     .dialog-message-wrapper {
        //         border-bottom: 0;
        //     }
        // }
    }
}