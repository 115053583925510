.room-modal-report {
    position: relative;
    width: 100% !important;
    max-height: calc(100% - 58px) !important;
    overflow-y: auto !important;
    padding: 24px 24px 40px !important;
    
	font-family: 'PTRootUI';
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.3px;
    color: #191919;

    label {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #191919;
    }

    .room-modal-report-why {
        margin-top: 12px;

        font-family: 'PTRootBold';
        line-height: 120%;
        letter-spacing: -0.6px;
        color: #191919;
    }

    .room-modal-report-optional {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.6);
    }

    .room-modal-report-text-input-others {
        width: 100%;
        padding: 8px;
        margin-top: 8px;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #191919;
        // border: 0.1px solid rgba(25, 25, 25, 0.6) !important;
        border-radius: 8px;
    }

    .room-modal-report-text-input-others-error {
        width: 100%;
        padding: 8px;
        margin-top: 8px;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #191919;
        border-radius: 8px;
        border-color: var(--main-dark-red);
    }

    .room-modal-report-text-input {
        position: relative;
        width: 100%;
        height: 200px;
        padding: 8px;
        margin-top: 8px;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #191919;
        border-radius: 8px;
    }

    .room-modal-report-letter-count {
        width: 100%;
        margin-top: 4px;
        text-align: right;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
    }
    
    p {
        &.room-modal-report-error {
            margin-top: 2px;
            margin-left: 8px;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            color: #FF3F57;
        }
    }
    
    button {
        vertical-align: top;

        &.room-modal-report-submit-button {
            // margin: 0;
            // width: calc(100% - 132px);
            width: 100%;
            display: inline-block;
          }
      }

    .room-modal-report-submit-button {
        // margin: 0;
        // width: calc(100% - 132px);
        width: 100%;
        display: inline-block;
      }
}
