.modal-mute-option {
    .modal-content {
        width: 300px;
        font-size: 16px;
        letter-spacing: -0.5px;
        border-radius: 8px;

        .modal-body {
            padding: 0;
            overflow: hidden;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                height: 48px;
                text-align: center;
                width: 100%;
                cursor: pointer;
                line-height: 48px;

                &:nth-child(1) {
                    border-radius: 8px 8px 0 0;
                }

                &:nth-last-child(1) {
                    border-radius: 0 0 8px 8px;
                }

                &:hover {
                    background: #ff7d00;
                    color: #fff;
                }
            }
        }
    }
}