.profile-carousel-wrapper {
    // margin-top: 3px;
    .carousel {
        ul {
            li {
                div {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    // make square
                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    img {
                        position: absolute; /* Take your picture out of the flow */
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0; /* Make the picture taking the size of it's parent */
                        width: 100%; /* This if for the object-fit */
                        height: 100%; /* This if for the object-fit */
                        object-fit: contain; /* Equivalent of the background-size: cover; of a background-image */
                        object-position: center;
                    }
                }
            }
        }
        &.carousel-slider {
            overflow: unset;
        }
        .control-dots {
            top: -3px;
            margin: 0;
            height: 3px;
            bottom: unset;
            display: flex;
            align-items: center;

            .dot {
                display: block;
                height: 3px;
                border-radius: 2px;
                box-shadow: unset;
                background: #FFF2E5;
                flex: 1;
                margin: 0;

                &.selected {
                    background: linear-gradient(135deg, #FFAB3A 0%, #FF7E00 100%);
                }
            }
        }
    }
}