.modal-option-mute-unmute {
    background: rgba(25, 25, 25, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 30px 52px;
    text-align: center;
    transition: ease-in-out .2s;
    opacity: 0;
    visibility: hidden;

    &.modal-option-mute-unmute-show {
        opacity: 1;
        visibility: visible;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 30px;
        left: 20px;
        display: inline-block;
        width: calc(100% - 40px);
        border-radius: 8px;

        li {
            width: 100%;
            background: #fff;
            text-align: center;
            font-size: 16px;
            letter-spacing: -0.5px;
            cursor: pointer;
            color: #ff7d00;
            height: 48px;
            line-height: 48px;
            border-bottom: solid 1px #dcdcdc;

            &:nth-child(1) {
                border-radius: 8px 8px 0 0;
            }

            &:nth-last-child(2) {
                border-radius: 0 0 8px 8px;
                border-bottom: 0;
            }

            &:nth-last-child(1) {
                border-radius: 8px;
                border-bottom: 0;
                margin-top: 24px;
            }

            &:hover {
                color: #E87200;
            }
        }
    }

    &.modal-option-mute-unmute-1 {
        ul {
            li {
                border-radius: 8px !important;
            }
        }
    }
}