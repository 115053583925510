.room-modal-star-message {
    position: relative;
    width: 100% !important;
    max-height: calc(100% - 58px) !important;
    overflow-y: auto !important;
    padding: 0 0 20px !important;

    .room-modal-star-message-wrapper {
        width: 100%;
        height: 1px;
        position: relative;
        padding: 0 24px 16px;
        text-align: center;
        
        div {
            border-top: solid 1px #dcdcdc;
            display: inline-block;
            width: 100%;
        }
    }

    .no-starred-message {
        width: 100%;
        text-align: center;

        .no-starred-message-img {
            margin: 44px 0 26px;
        }

        .no-starred-title {
            margin: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .no-starred-desc {
            margin: 12px 0 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
        }
    }

    .message-action-wrapper-new {
        display: none !important;
    }

    .loading-message-wrapper {
        padding-top: 16px;
        text-align: center;
        width: 100%;

        .lds-ring {
            div {
                border-color: #ff7d00 transparent transparent transparent;
            }
        }
    }
}