.shared-media-tab-button-container {
    position: relative;
    display: inline-block;
    height: 100%;
    cursor: pointer;

    .shared-media-tab-button-label {
        height: 100%;
        // font-style: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 3px;
        text-transform: uppercase;
        padding-left: 12px;
        padding-right: 12px;
        color: var(--main-dark-orange);
    }

    .shared-media-tab-active-indicator {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: 0 auto;
        width: calc(100% - 20px);
        height: 2px;
        background-color: var(--main-dark-orange);
    }
}
            