.room-info-modal {
    .CircularProgressbar .CircularProgressbar-trail {
        stroke: transparent;
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: #ffffff;
    }

    .modal-header {
        background: #ffffff;
        position: relative;
        text-align: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 5px 15px;
        height: auto;
        overflow: hidden;
        width: 100%;
        flex-shrink: 0;

        .header-room-info-button {
            font-size: 30px;
            position: absolute;
            top: 243px;
            cursor: pointer;
            transition: ease-in-out .2s;

            &:hover {
                color: #ff7d00;
            }

            &.back-arrow-modal {
                width: 32px;
                height: 32px;
                color: var(--main-orange);
                left: 0 !important;
                top: 0 !important;
                // transform: translate(0, -50%);
            }

            &.user-info-back-arrow-modal {
                top: 50% !important;
                margin-top: -16px;
                left: 0 !important;
                width: 32px;
                height: 32px;
                color: var(--main-orange);
            }

            &.close-room-info {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        .modal-title {
            margin: 0;
            position: relative;
            white-space: nowrap;
            // padding: 0 40px;
            overflow: hidden;
            text-overflow: ellipsis;

            .modal-room-info-content {
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
            }
        }

        .room-info-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
            line-height: 24px;
            margin: auto;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 80px);
            display: inline-block;
            position: relative;
            top: 4px;
        }

        .header-top-title-chatroom {
            height: auto;
            width: 100%;
            overflow: hidden;
            // display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;

            svg {
                width: 32px;
                height: 32px;
                cursor: pointer;
                color: var(--main-orange);
                flex-shrink: 0;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }

            p {
                margin: 0;
                color: var(--main-orange);
                cursor: pointer;
                font-family: "PTRootUI", sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.5px;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }

            .room-info-name-wrapper {
                position: relative;
                width: calc(100% - 132px);
                display: inline-block;
                text-align: center;
                // padding-left: 16px;
                // height: 80px;
                vertical-align: top;
                white-space: nowrap;
    
                .room-info-name-content {
                    padding: 4px 0 0;
                    width: 100%;
                    // position: absolute;
                    // top: 50%;
                    // transform: translate(0, -50%);

                    &.room-saved-message-name-content {
                        padding: 14px 0 !important
                    }
    
                    p {
                        margin: 0;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        letter-spacing: -0.6px;
                        color: #191919;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
    
                    .p2 {
                        color: rgba(25, 25, 25, 0.6);
                        letter-spacing: -0.2px;
                        font-size: 14px;
                        font-weight: normal;
                    }
    
                    span {
                        color:rgba(25, 25, 25, 0.6);
                        font-weight: normal;
                    }
                }
            }
        }

        &.member-list-header {
            background: #ffffff;
            padding: 14.5px 16px !important;
            position: relative;
            height: unset;
            box-shadow: 0px 1px 0px 0px rgba(25, 25, 25, 0.1);

            svg {
                top: 12px;
                // right: 11px;
                font-size: 32px;
            }

            .clear-selected-member {
                letter-spacing: -0.2px;
                position: absolute;
                cursor: pointer;
                top: 1px;
                right: 15px;
                color: #ff7e00;
            }
        }
    }

    .modal-content {
        width: 435px;
        border-radius: 8px;
        max-height: calc(100vh - 40px);
        overflow: hidden;
        height: 100%;
        background: #f3f3f3;
        display: flex !important;
        flex-direction: column;

        .room-info-name-avatar {
            position: relative;
            width: 100%;
            display: inline-block;
        }

        .room-info-avatar-wrapper {
            width: 100%;
            position: relative;
            text-align: center;
            padding: 0;
            margin-bottom: 24px;

            .room-info-name-wrapper-saved-message {
                background: var(--main-dark-orange);
                
                svg {
                    color: #fff;
                    font-size: 160px;
                    margin: 90px 0;
                }
            }

            #edit-group-avatar-file {
                display: none;
            }
        }

        .room-info-avatar {
            line-height: 92px;
            font-size: 92px;
            letter-spacing: -0.6px;
            text-transform: uppercase;
            // margin: 50px 0 18px;
            width: 100%;
            background: #aff2c0;
            color: white;
            display: inline-block;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            img {
                position: absolute; /* Take your picture out of the flow */
                top: 0;
                bottom: 0;
                left: 0;
                right: 0; /* Make the picture taking the size of it's parent */
                width: 100%; /* This if for the object-fit */
                height: 100%; /* This if for the object-fit */
                object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
                object-position: center;
            }

            > div {
                width: 105px;
                position: absolute;
                margin-top: -40px;
                top: 45%;
                left: 50%;
                margin-left: -52.5px;
            }

            .no-avatar-placeholder-wrapper {
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                .no-avatar-placeholder {
                    width: 105px;
                    position: absolute;
                    margin-top: -40px;
                    top: 50%;
                    left: 50%;
                    margin-left: -52.5px;
                    text-transform: uppercase;
                }
            }
        }

        .modal-room-info-content {
            left: -500px;
            transition: ease-in-out .3s;
            position: relative;

            &.active-modal-room-info-content {
                left: 0;
            }

            &.active-circle-modal-room-info {
                left: 0;
                margin: 10px 0;
            }
        }

        .room-info-member-list {
            right: -500px;
            transition: ease-in-out .3s;
            position: relative;
            padding: 0;
            background: #F3F3F3;
            max-height: calc(100% - 120px);

            &.modal-header {
                background: #ffffff;
            }

            &.header-add-member {
                .contact-name {
                    p {
                        max-width: calc(100% - 46px);
                    }
                }
            }

            &.active-room-info-member-list {
                right: 0;
            }

            .member-lists {
                margin: 0;
                padding: 0;
                // box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.5);

                li {
                    list-style: none;
                    padding-left: 72px;
                    position: relative;
                    cursor: pointer;
                    background: #ffffff;

                    .click-member-area {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        width: 100%;
                        display: inline-block;
                        cursor: pointer;
                        height: 76px;
                        width: calc(100% - 49px);
                    }
    
                    .member-list-avatar {
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        position: absolute;
                        left: 16px;
                        top: 14px;

                        img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 50%;
                            object-fit: cover;
                            width: 48px;
                            height: 48px;
                        }

                        div {
                            background: rgb(66, 57, 190);
                            height: 48px;
                            width: 48px;
                            text-align: center;
                            border-radius: 50%;
                            color: #ffffff;
                            line-height: 48px;
                        }
                    }

                    .member-list-name-wrapper {
                        border-bottom: solid 1px #dcdcdc;
                        height: 76px;
                        width: 100%;
                        position: relative;

                        .member-list-name-content {
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            width: 100%;
                        }

                        p {
                            margin: 0;
                            font-size: 16px;
                            letter-spacing: -0.5px;
                            max-width: calc(100% - 38px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        span {
                            color: rgba(25, 25, 25, 0.6);
                            font-size: 14px;
                            letter-spacing: -0.2px;
                        }
                    }

                    &:nth-last-child(1) {
                        .member-list-name-wrapper {
                            border-bottom: 0;
                        }
                    }

                    label {
                        position: absolute;
                        right: 12px;
                        top: 40%;
                    }

                    
                    &:hover {
                        background: #f3f3f3;

                        .member-list-name-wrapper {
                            border-bottom: solid 1px #f3f3f3;
                        }
                    }
                }
            }

            .member-count-wrapper {
                text-align: center;
                font-size: 16px;
                letter-spacing: -0.5px;
                padding: 30px 0;
                color: rgba(25, 25, 25, 0.4);
            }
        }

        .member-list-action-button-wrapper {
            width: 100%;
            height: 72px;
            padding: 12px;
            background: #ffffff;

            button {
                border: none;
                border-radius: 8px;
                color: #ffffff;
                text-align: center;
                width: 100%;
                height: 100%;
                font-size: 16px;
                letter-spacing: -0.5px;
                line-height: 25px;
                padding: 11px 0;
            }

            .member-add {
                background: #ff7e00;
            }

            .member-delete {
                background: #ff3f57;
                color: #ffffff;

                .lds-ring {
                    position: relative;
                    top: 4px;
                }
            }

            svg {
                position: relative;
                top: 3px;
                font-size: 20px;
                vertical-align: top;
            }
        }

        .edit-group-avatar-file {
            cursor: pointer;

            .progress-upload-group-photo-wrapper {
                border-radius: 50%;
                position: absolute;
                padding: 5px;
                line-height: 0;
                width: 80px;
                height: 80px;
                background: rgba(25, 25, 25, 0.4);
            }
        }
    }

    .modal-body {
        background: #f3f3f3;
        padding: 0 24px;
        position: relative;
        height: 100%;
        /* min-height: calc(100vh - 105px); */
        // overflow-y: auto;
        overflow-y: auto;
        // width: calc(100% + 17px);

        .profile-info-wrapper {
            background-color: transparent;
            padding: initial;
            margin: initial;
            margin: 24px 0 0;
            border-radius: 8px;

            .input-my-account {
                padding-bottom: 24px;
    
                input {
                    padding: 16px;
                    line-height: 1;
                    width: 100%;
                    border-radius: 8px;
                    margin: 8px 0 0;
                    font-size: 16px;
                    letter-spacing: -0.3px;

                    &[disabled] {
                        background: rgba(25, 25, 25, 0.1);
                        border: none;
                    }
                }
    
                .username-rule {
                    display: block;
                    font-size: 14px;
                    line-height: 21px;
                }
    
                &.phone-input-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    label {
                        // color: rgba(25, 25, 25, 0.2);
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        line-height: 21px;
                        flex: 0 0 100%;
                    }

                    input {
                        width: calc(100% - 107px);
                        display: inline-block;
                        vertical-align: top;
                    }
    
                    .phone-input {
                        color: #191919;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        line-height: 1;
                        padding: 15px 16px 16px;
                        background: rgba(25, 25, 25, 0.1);
                        border: none;
                        width: 90px;
                        display: inline-block;
                        margin-right: 17px;
                        vertical-align: top;
                    }

                    
                }
            }
        }

        .profile-info-wrapper.not-edit {
            background-color: #FFFFFF;
            padding: 9px 24px;

            .input-my-account {
                padding-bottom: 19px;

                &:last-child {
                    padding-bottom: 0;
                }
            }

            label {
                font-family: 'PTRootUI', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: #FF7E00;
            }

            input {
                padding: 0;
                line-height: 1;
                background: #ffffff;
                background: transparent;

                &[disabled] {
                    background: #ffffff;
                    background: transparent;
                    border: none;
                }
            }
        }

        .view-member-wrapper {
            max-height: calc(100% - 255px);
            height: 100%;
        }

        .action-wrapper {
            width: 100%;
            background: #ffffff;
            padding: 0;
            border-radius: 8px;

            ul {
                margin: 0;
                list-style: none;
                padding: 0;

                li {
                    height: 48px;
                    line-height: 48px;
                    position: relative;
                    border-bottom: solid 1px #dcdcdc;
                    cursor: pointer;
                    padding-left: 42px;

                    &.mute-unmute-option {
                        img {
                            top: 17px !important;
                        }
                    }

                    .mute-unmute-value {
                        color: rgba(25, 25, 25, 0.6);
                        letter-spacing: -0.3px;
                        font-size: 16px;
                        display: inline-block;
                        position: absolute;
                        right: 38px;
                        top: 0;
                        height: unset;
                        margin: 0;
                    }

                    &:nth-last-child(1) {
                        border: none;
                    }

                    img {
                        position: absolute;
                        left: 12px;
                        top: 13px;
                        width: 22px;
                    }

                    svg {
                        color: grey;
                        font-size: 25px;
                        position: absolute;
                        right: 12px;
                        top: 12px;
                    }

                    .lds-ring {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);

                        div {
                            border-color: #ff7e00 transparent transparent transparent;
                        }
                    }

                    &:hover {
                        color: #ff7d00;
                    }

                    &.action-red {
                        color: rgba(255, 63, 87, 1);

                        &:hover {
                            color: #ff0020 !important;
                        }
                    }

                    > div:last-child {
                        position: absolute !important;
                        right: 12px;
                        top: 50%;
                        margin-top: -13px;
                    }
                }
            }
            
            &.bottom-action-wrapper {
                margin: 24px 0;
            }
        }

        .shared-media-info {
            width: 100%;
            position: relative;
            padding: 10px 0 20px;
            background: #FFFFFF;
            border-radius: 8px;

            .shared-media-title {
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 2.5px;
                text-transform: uppercase;
                padding-left: 16px;

                color: var(--main-dark-orange);
            }

            .shared-media-list-wrapper {
                padding: 8px 16px 0;
            }
        }
    }

    .edit-group-header {
        position: fixed;
        z-index: 2;

        .modal-room-info-content {
            position: relative;

            .header-room-info-button {
                position: absolute;
                // right: 0;
                top: 0;
                // left: 0 !important;
            }
        }
    }

    .edit-group-modal {
        // height: calc(100% - 255px);

        &.modal-body {
            padding: 55px 24px 0 24px;
        }

        label {
            letter-spacing: -0.4px;
        }

        .room-info-avatar {
            > div {
                top: 40%;
            }
        }

        input[type="text"] {
            width: 100%;
            padding: 13px 16px;
            border-radius: 8px;
            margin-top: 10px;
        }

        .error-edit-group {
            color: red;
            text-align: center;
        }

        button {
            width: calc(100% - 32px) !important;
            padding: 15px 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            position: fixed;
            left: 16px;
            bottom: 16px;

            .lds-ring {
                top: 2px;
            }
        }
    }

    .loading-contact-wrapper {
        width: 100%;
        text-align: center;

        .lds-ring {
            div {
                border-color: #ff7e00 transparent transparent transparent;
            }
        }
    }

    .error-member-detail {
        color: red;
        text-align: center;
        // margin-top: 20px;
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        .modal-content {
            max-height: unset;
            width: 100%;
            height: 100%;
            border-radius: 0;

            .modal-body {
                width: 100%;
                max-height: unset;
                height: calc(100vh - 53px);
            }

            .modal-header {
                border-radius: 0;
            }
        }
    }
}

.modal-video-image {
    .modal-content {
        width: 100%;
        height: 100%;
        
        .modal-body {
            width: 100%;
            height: 100%;
            background: #191919;

            .video-image-wrapper {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 70vw;
                height: 70vh;
                text-align: center;

                // .image-wrapper {
                //     display: inline-block;
                //     position: absolute;
                //     top: 50%;
                //     left: 50%;
                //     transform: translate(-50%, -50%);
                //     text-align: center;
                
                //     figure {
                //       margin: 0;
                      
                //       &.zoom {
                //         background-size: 180%;
                //         background-position: -100% -100%;
                //         background-repeat: no-repeat;
                //         position: relative;
                //         width: 100%;
                //         overflow: hidden;
                //         cursor: zoom-in;
                
                //         img{
                //           transition: opacity 0.5s;
                //           display: inline-block;
                //           // width: 100%;
                
                //           &:hover {
                //             opacity: 0;
                //           }
                //         }
                //       }   
                //     }
                // }

                img {
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .close-modal-video {
                position: absolute;
                top: 20px;
                right: 20px;
                color: #ffffff;
                font-size: 40px;
                cursor: pointer;
                transition: ease-in-out .1s;

                &:hover {
                    color: #c9c9c9;
                }
            }
        
            video {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}

.modal-info-confirmation {
    .modal-content {
        border-radius: 8px;
        width: 450px;
        max-width: 100%;
    }

    .modal-body {
        padding: 30px;
        border-radius: 8px;

        .modal-info-confirmation-title {
            font-size: 20px;
            letter-spacing: -0.6px;
        }

        .modal-info-confirmation-desc {
            color: rgba(25, 25, 25, 0.8);
            font-size: 16px;
            letter-spacing: -0.3px;
        }

        .modal-info-confirmation-error {
            color: red;
            margin-top: 0;
        }

        .modal-info-confirmation-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                width: 50%;
                border-radius: 8px;
                // border: none !important;
                text-align: center;
                margin-left: 10px;
                cursor: pointer;
                vertical-align: top;
                font-size: 16px;
                height: 40px;
                line-height: 1px;

                b {
                    position: relative;
                    top: -1px;
                }

                &:nth-child(1) {
                    color: rgba(25, 25, 25, 0.6);
                    letter-spacing: -0.5px;
                    background: none;
                    margin-left: 0;

                    &:hover {
                        color: rgba(25, 25, 25, 0.5);
                    }
                }

                .lds-ring {
                    top: 0px;
                }
            }
        }
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        .modal-content {
            width: calc(100% - 40px);
        }
    }
}

.loading-wrapper {
    width: 100%;
    padding-top: 20px;
    text-align: center;

    .lds-ring {
        div {
            border-color: #ff7e00 transparent transparent transparent;
        }
    }
}