.register-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, #FFAB3A 0%, #FF7D00 100%);

    &::before {
        content: "";
        background: url('../../assets/img/login-background-left-assets.svg');
        background-repeat: no-repeat;
        background-size: 33vw;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 33.2vw;
        height: 37.1vw;
        z-index: -1;
    }

    &::after {
        content: "";
        background: url('../../assets/img/login-background-right-assets.svg');
        background-repeat: no-repeat;
        background-size: 31.4vw;
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 50%;
        width: 31.4vw;
        height: 40vw;
        z-index: -1;
    }

    .register-main-content {
        padding: 0 48px;
        width: 100%;
        height: 100%;
        text-align: center;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .logo-image {
            margin-top: 80px;
        }

        .subtitle {
            margin: 4px 0 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 4px;
            color: white;
            text-transform: uppercase;
        }

        .register-card {
            padding: 48px;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 40px;
            width: 100%;
            max-width: 640px;
            border-radius: 24px;
            background: #ffffff;
            box-shadow: 0px 2px 20px 0px #1919192E;

            b {
                font-size: 40px;
                letter-spacing: -0.6px;
                color: #191919;
            }

            p {
                margin: 16px 0 32px;
                font-size: 14px;
                letter-spacing: -0.2px;
                line-height: 21px;
                color: #19191999;
            }

            .avatar-wrapper {
                margin: auto;
                width: 120px;
                height: 120px;
                border-radius: 60px;
                background-color: #C3C8D0;

                svg {
                    position: relative;
                    width: 64px;
                    height: 64px;
                    top: 28px;
                    color: white;
                }

                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 60px;
                    object-fit: cover;
                }
            }

            .select-profile-picture {
                display: inline-block;
                margin-top: 8px;
                cursor: pointer;

                b {
                    display: inline-block;
                    font-size: 14px;
                    letter-spacing: -0.5px;
                    line-height: 18px;
                    color: #FF7E00;
                }

                img {
                    display: inline-block;
                    margin-left: 6px;
                    width: 14px;
                    height: 14px;
                    color: #FF7E00;
                    transform: translateY(4px);
                }
            }

            #select-profile-picture-input {
                display: none;
            }

            .form-field-wrapper {
                margin: 16px 0 0;
                text-align: left;

                b {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.4px;
                    text-align: left;
                    color: #191919;
                }

                p {
                    display: inline-block;
                    margin: 0 0 0 4px;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    text-align: left;
                    color: #19191999;
                }

                .text-field-wrapper {
                    margin-top: 8px;
                    width: 100%;
                    position: relative;
                    border: solid 1px #1919191A;
                    border-radius: 8px;
                    text-align: left;

                    &:focus-within {
                        border: solid 1px #ff7e00;
                    }

                    input {
                        padding: 12px 16px;
                        width: 100%;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        line-height: 24px;
                        color: #191919;
                        caret-color: #FF7E00;
                        border-radius: 8px;
                        border: none;
                        background: none;
                        -webkit-box-shadow: 0 0 0 50px white inset;
                        box-shadow: 0 0 0 50px white inset;
                
                        &::placeholder {
                            color: #19191966;
                            opacity: 1;
                            }
                            
                        &::-ms-input-placeholder {
                            color: #19191966;
                            opacity: 1;
                        }
                        
                        &::-webkit-input-placeholder {
                            color: #19191966;
                            opacity: 1;
                        }
                    }

                    img {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        top: 12px;
                        right: 16px;
                    }
                }

                .phone-wrapper {
                    margin-top: 8px;
                    padding: 12px 16px;
                    width: 100%;
                    position: relative;
                    border-radius: 8px;
                    text-align: left;
                    border: none;
                    background: #1919191A;
                    color: #19191966;

                    img {
                        display: inline-block;
                        width: 32px;
                        border-radius: 2px;
                        filter: grayscale(0) !important;
                    }

                    svg {
                        display: inline-block;
                        margin: 0 4px;
                        width: 16px;
                        height: 16px;
                        transform: translateY(4px);
                    }

                    p {
                        display: inline-block;
                        margin: 0 0 0 4px;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        line-height: 24px;
                    }
                }

                .error {
                    border: solid 1px #FF3F57;
                }

                .loading {
                    border: none;
                    background: #1919191A;
                    color: #19191966;

                    input {
                        color: #19191966;
                        -webkit-box-shadow: none !important;
                        box-shadow: none !important;
                    }

                    .phone-country {
    
                        p {
                            color: #19191966;
                        }
                    }
                }
            }

            .privacy-policy-wrapper {
                margin: 16px 0 0;
                text-align: left;

                img {
                    display: inline-block;
                    margin-right: 8px;
                    width: 24px;
                    height: 24px;
                    // transform: translateY(-2px);
                    cursor: pointer;
                }

                p {
                    display: inline-block;
                    margin: 0;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    line-height: 24px;
                    color: #191919;

                    a {
                        margin-left: 4px;
                        color: #FF7E00;
                    }
                }
            }

            .input-error-info {
              display: block;
              margin-top: 8px;
              width: 100%;
              height: 18px;
              color: #FF3F57;
              text-align: left;
            
              svg {
                display: inline-block;
                vertical-align: top;
                margin-right: 4px;
                height: 16px;
              }
            
              b {
                display: inline-block;
                vertical-align: top;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.4px;
                color: #FF3F57;
                transform: translateY(-1px);
              }
            }

            button {
                width: 100%;
                padding: 14px 32px;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.5px;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-left: 4px;
                }

                .lds-ring {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    transform: translateY(2px);

                    div {
                        width: 16px;
                        height: 16px;
                        border-color: #A3A3A3 transparent transparent transparent;
                    }
                }
            }

            .loading {
                b, p {
                    color: #19191966 !important;
                }

                a {
                    color: #19191999 !important;
                }
                
                img {
                    filter: grayscale(100%);
                }
            }
        }
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .register-main-content {
        padding-top: 34px;
    }
}
