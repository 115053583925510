.chat-room-main-wrapper {
    max-height: calc(100% - 57px);
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;

    .custom-checkbox-forward {
        display: inline-block;
        vertical-align: top;

        &.custom-checkbox-forward-out {
            label {
                padding-left: 17px;
            }

            margin-left: 4px;
        }

        &.custom-checkbox-forward-in {
            label {
                padding-left: 17px;
            }

            margin-right: 4px;
        }
    }

    &.hide-reply-forward {
        // .reply-button {
        //     display: none;
        // }

        // .forward-button {
        //     display: none;
        // }
        .message-action-wrapper {
            display: none !important;
        }
    }

    .chat-room-main-wrapper-firstchat-blocking {
        position: absolute;
        background: #f3f3f3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: solid 1px #dcdcdc;
        border-left: solid 1px #dcdcdc;
        padding: 0 24px;
        z-index: 1;
        text-align: center;
        
        .chat-room-main-wrapper-firstchat-blocking-content {
            letter-spacing: -0.6px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            width: 100%;
            line-height: 24px;

            .avatar-outer-wrapper {
                // width: 40px;
                // height: 40px;
                display: inline-block;
                // margin-right: 12px;
                vertical-align: top;
                text-align: center;
                padding-bottom: 6px;
        
                .avatar-content {
                    cursor: pointer;
                    width: 72px;
                    height: 72px;
                    position: relative;
                    border-radius: 50%;
                    background: #f3f3f3;
                    white-space: nowrap;
                    display: inline-block;
                    border: solid 4px #f3f3f3;  
                    margin: 0 -4px;
        
                    img {
                        object-fit: cover;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                    }

                    &.recipient-avatar {

                    }
                }
        
                .avatar-abbreviation {
                    border-radius: 50%;
                    background: #aff2c0;
                    color: white;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    letter-spacing: -0.6px;
                    line-height: 64px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 24px;
                    text-transform: uppercase;
                    transform: translate(-50%, -50%);
                }
            }

            p {
                margin: 0;
            }

            br {
                display: block;
                margin: 10px 0;
            }

            span {
                font-size: 16px;
                color: #696969;
            }
        }
    }

    .chat-room-main-wrapper-loading-blocking {
		position: absolute;
		background: #f3f3f3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		border-top: solid 1px #dcdcdc;
		border-right: solid 1px #dcdcdc;

		.lds-ring {
			width: 32px;
			height: 32px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			div {
				border: solid 3px #ffffff;
				border-color: #ff7d00 transparent transparent transparent;
				width: 32px;
				height: 32px;
			}
		}
	}

    .room-is-unavailable {
        padding: 4px 16px;
        border-radius: 8px;
        box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
        display: inline-block;
        background: #ffffff;
        color: rgba(25, 25, 25, 0.4);
        font-size: 12px;
        letter-spacing: -0.4px;
        line-height: 18px;
        text-align: center;
        position: absolute;
        top:40%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .no-saved-message-container {
        padding: 12px 16px;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
        border-radius: 8px;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 255px;

        p {
            font-size: 16px;
            letter-spacing: -0.5px;
            color: #000000;
            line-height: 24px;
            text-align: center;
            margin: 0 0 8px;
        }

        ul {
            margin: 0;
            
            li {
                letter-spacing: -0.2px;
                font-size: 14px;
                color: #000000;
                line-height: 21px;
                text-align: left;
            }
        }
    }

    .chat-room-main-content {
        padding: 0 0 16px;
        // max-height: calc(100vh - 120px);
        max-height: 100%;
        overflow-y: auto;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        
        // height: 100%;

        .loading-message-wrapper {
            padding-top: 16px;
            text-align: center;
            width: 100%;

            .lds-ring {
                div {
                    border-color: #ff7d00 transparent transparent transparent;
                }
            }
        }
    }
}

.no-longer-participant {
    text-align: center;
    background: #fff4b1;
    width: 100%;
    color: #ff7d00;
    padding: 12px;

    img {
        width: 16px;
        position: relative;
        top: 3px;
        margin-right: 5px;
    }

    p {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0;

        &:nth-child(2) {
            margin: 7px 0 15px;
            font-size: 16px;
            letter-spacing: -0.2px;
        }
    }

    button {
        width: 100%;
        padding: 10px 0;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: -0.5px;
    }
}

@keyframes highlight {
    0% {
        background: #FFF4B1; 
    }
    100% {
        background: none;
    }
}

.highlight-chat-bubble {
    animation: highlight 2s;
}

.with-reply {
	img, video {
		border-radius: 0 !important;
	}
}

.hide-reply-button {
	.reply-button {
		display: none !important;
	}
}

.message-body, .caption-text {
	cursor: text;
}

.message-action-button-wrapper {
	cursor: pointer;

	&:hover {
		background: rgba(25, 25, 25, 0.05);

		svg {
			color: rgba(25, 25, 25, 0.8) !important;
		}
	}
}

.tap-hyperlink {
	color: unset !important;
	text-decoration: underline !important;
    cursor: pointer;
}

.message-in-bubble {
	.tap-hyperlink {
		color: #039be5 !important;
		text-decoration: underline !important;
	}
}

.message-info {
    img {
        vertical-align: middle !important;
    }
}

.wrap-all-chatroom {
	width: 100%;
	// height: calc(100vh - 104px);
	height: calc(100% - 52px);
	transition: ease-in-out .2s;
	display: inline-block;
	font-size: 0;
    position: relative;
      
    &.chat-room-with-header-height-100 {
        height: calc(100% - 100px) !important;
    }  
  
    .drop-file-here-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        text-align: center;
        font-size: 24px;
        letter-spacing: -0.5px;
        background: #eaeaea;
        // transition:  all .3s ease 0s;
        z-index: 3;

        &.active-drop-file-here-wrapper {
        display: block;
        }

        svg {
        font-size: 48px;
        margin-bottom: 16px;
        }

        p {
        margin: 0;
        }

        .drop-file-here-content {
        width: 80%;
        height: 80%;
        padding: 40px;
        border: dashed 4px #191919;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        line-height: 80%;
        color: #191919;
        
        .drop-file-here-inner-content {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 24px;
        }
        }
    }

    .group-sender-avatar-wrapper {
        img {
            object-fit: cover;
        }
    }

    .mention-counter {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border: solid 1px #dcdcdc;
        border-radius: 50%;
        text-align: center;
        font-size: 20px;
        color: #191919;
        background: #ffffff;
        position: absolute;
        z-index: 1;
        line-height: 34px;
        right: 30px;
        bottom: 16px;

        .mention-counter-badge {
            position: absolute;
            font-size: 12px;
            letter-spacing: -0.1px;
            height: 22px;
            min-width: 22px;
            border-radius: 50%;
            background: #ff7d00;
            color: #ffffff;
            text-align: center;
            line-height: 22px;
            padding: 0 4px;
            left: 50%;
            transform: translate(-50%, 0);
            top: -14px;
        }
    }
}

.marker-map {
    position: relative;
    width: 1px;
    height: 1px;

    img {
        position: absolute;
        top: -31px;
        left: 50%;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.modal-action-message {
    .modal-content {
		width: 100%;
		height: 100%;
		background: transparent;

		.modal-body {
			width: 100%;
			height: 100%;
			background: transparent;
			position: relative;
            
            .action-message-wrapper-chat {
                width: calc(100% - 16px);
                display: inline-block;
                box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
                line-height: 1;
                position: absolute;
                left: 8px;
                bottom: 76px;
                border-radius: 8px;
                z-index: 1;
                overflow: hidden;

                .action-message-content {
                    width: 100%;
                    position: relative;
                    padding: 12px 12px 12px 52px;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    color: #191919;
                    border-bottom: solid 1px #dcdcdc;
                    background: #fff;
                    cursor: pointer;
            
                    &:hover {
						color: #ffffff !important;
                        background: #ff7d00;
	
						svg {
							color: #ffffff !important;
						}
					}
            
                    svg {
                        top: 10px;	
                    }

                    &:nth-last-child(1) {
                        border: 0;
                    }

                    &.action-message-forward {
                        svg {
                            transform: scaleX(-1);
                        }
                    }
                }
                
                svg {
                    left: 16px;
                    font-size: 20px;
                    position: absolute;
                    color: #ff7d00;
                }

            }

            .cancel-action-message {
                width: calc(100% - 16px);
                position: absolute;
                bottom: 8px;
                background: #ffffff;
                border-radius: 8px;
                left: 8px;
                font-size: 20px;
                letter-spacing: -0.6px;
                height: 60px;
                color: #ff7d00;
                line-height: 60px;
                text-align: center;

                &:hover {
					background: #ff7d00 !important;
					color: #fff !important;
				}
            }
        }
    }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
    .message-action-wrapper-new {
        display: none !important;
    }
}

.chat-room-forward-message-area-selectarea-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
}

.no-scheduled-message-container {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    padding: 0px 60px 0px;

    .no-scheduled-message-desc {
        margin: 12px 0 0;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;
    }
}
