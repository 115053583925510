.chat-room-input-voice-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	height: 56px;
	background: #fff;
	// width: calc(100% - 88px);
	width: 100%;
	z-index: 2;
	transition: ease-in-out .2s;
	left: 0;

	#waveform {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		// background: pink;
		z-index: 1;
		left: 60px;
		height: 36px;
		width: calc(100% - 250px);

		wave {
			height:36px !important;

			wave {
				border-right: 0 !important;
			}
		}
	}

	.button-stop-wrapper {
		font-size: 22px;
		background: none;
		border: 0;
		cursor: pointer;
		color: #ff7d00;
		bottom: 10px;
		position: absolute;
		right: 56px;

		&:hover {
			color: #E87200;
		}
	}

	.remove-recorder-icon {
		cursor: pointer;
		color: #FF3F57;
		font-size: 22px;
		position: absolute;
		left: 18px;
		top: 50%;
		transform: translate(0, -50%);

		&:hover {
			color: #e9001d;
		}
	}

	.circle-recorder {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: #ff7d00;
		position: absolute;
		right: 147px;
		top: 50%;
		transform: translate(0, -50%);
		animation: 1.5s blinking-recorder infinite;

		@keyframes blinking-recorder {
			from {
				opacity: 1;
			}

			to {
				opacity: 0;
			}
		}
	}
	
	.recorder-timer {
		color: #ff7d00;
		letter-spacing: -0.3px;
		font-size: 16px;
		position: absolute;
		right: 97px;
		top: 50%;
		transform: translate(0, -50%);
	}
}