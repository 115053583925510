.modal-message-info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%;
    background: rgba(25,25,25,.8);
    visibility: hidden;
    opacity: 0;
    right: 0;
    transition: visibility .3s,opacity .5s ease-in-out;
    z-index: 9;

    &.active-modal-message-info {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s,opacity .5s ease-in-out;
    }

    .modal-message-info-body {
        position: absolute;
        top: 0;
        right: -100%;
        left: unset;
        -webkit-transform: unset;
        transform: unset;
        height: 100%;
        width: 600px;
        display: inline-block;
        border-radius: 8px 0 0 0;
        background: #fff;
        padding: 0;
        max-height: 100%;
        -webkit-transition: .4s ease-in-out;
        transition: .4s ease-in-out;

        &.active-modal-message-info-body {
            right: 0;
        }
    }

    .close-modal-message-info {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: #fff;
        color: #757575;
        position: absolute;
        left: -65px;
        top: 8px;
        text-align: center;
        cursor: pointer;

        svg {
            position: relative;
            font-size: 25px;
            top: 11px;
        }

    }

    .modal-message-info-title {
        font-size: 24px;
        letter-spacing: -0.6px;
        padding: 24px;
    }

    .modal-message-info-content-wrapper {
        width: 100%;
        height: 200px;
        overflow: auto;
        background: #efefef;
        border-top: solid 1px rgba(25,25,25,.1);
        padding: 18px 0;

        .message-action-wrapper-new {
            display: none;
        }

        .timestamp-wrapper {
            text-align: center;

            .timestamp {
                color: rgba(25, 25, 25, 0.6);
                background: #fff;
                border-radius: 8px;
                box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
                font-size: 12px;
                letter-spacing: -0.4px;
                padding: 4px 24px;
            }
        }
    }

    .read-delivered-wrapper {
        height: calc(100% - 278px);
        overflow: auto;
        position: relative;

        .read-delivered-content {
            // height: 50%;
            
            .read-delivered-title {
                padding: 12px 12px 0 12px;
                position: relative;
                height: 38px;
                background: #fff;
                width: 100%;

                img {
                    position: absolute;
                    right: 12px;
                    top: 15px;
                }

                p {
                    font-size: 10px;
                    padding: 0 0 12px 12px;
                    letter-spacing: 1.5px;
                    color: rgba(25, 25, 25, 0.6);
                    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
                    margin: 0;
                }

                &.read-delivered-title-sticky {
                    position: sticky;
                    left: 0;
                    top: 0;
                    width: 100%;
                    z-index: 1;
                }
            }

            .read-delivered-list-wrapper {
                padding: 0 12px;
                // overflow: auto;
                // height: calc(100% - 38px);
                position: relative;

                .lds-ring {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;

                    div {
                        border-color: #ff7d00 transparent transparent transparent;
                        width: 40px;
                        height: 40px;
                        border-width: 3px;
                    }
                }

                .read-delivered-list {
                    width: 100%;
                    display: block;
                    position: relative;
                    padding: 0 0 0 64px;
                    height: 64px;

                    .room-info-avatar {
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 6px;

                        .room-info-avatar-image {
                            border-radius: 50%;
                            width: 52px;
                            height: 52px;
                            display: inline-block;
                        }

                        .user-avatar-name {
                            border-radius: 50%;
                            width: 52px;
                            height: 52px;
                            display: inline-block;
                            text-align: center;
                            font-size: 20px;
                            letter-spacing: -0.6px;
                            color: white;
                            line-height: 52px;
                            text-transform: uppercase;
                        }
                    }

                    .message-info-main {
                        height: 64px;
                        width: 100%;
                        padding: 0 158px 0 0;
                        display: inline-block;
                        position: relative;
                        border-bottom: solid 1px rgba(25, 25, 25, 0.1);

                        .user-name {
                            font-size: 16px;
                            letter-spacing: -0.5px;
                            color: #191919;
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin: 22px 0 0 0;
                        }

                        .timestamp-read-delivered-list-wrapper {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(0, -50%);
                            padding: 0;
                            text-align: right;

                            .centered-dot {
                                width: 2px;
                                height: 2px;
                                display: inline-block;
                                vertical-align: top;
                                background: rgba(25, 25, 25, 0.6);
                                border-radius: 50%;
                                position: relative;
                                top: 9px;
                            }

                            p {
                                color: rgba(25, 25, 25, 0.6);
                                margin: 0;
                                font-size: 12px;
                                letter-spacing: -0.2px;

                                b {
                                    font-size: 14px;
                                }

                                &:nth-child(2) {
                                    margin: 8px 0 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}