.new-chat-modal {
    .modal-content {
        width: 435px;
        border-radius: 8px;
        max-height: calc(100vh - 40px);
        overflow: hidden;
        height: 100%;
        background: #f3f3f3;
        position: relative;
    }

    .modal-body {
        position: relative;
        background: #ffffff;
        min-height: calc(100vh - 40px);
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        .modal-content {
            width: 100%;
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }
    }
}