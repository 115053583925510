.message-info {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 24px; */
    width: 100%;
    white-space: nowrap;
    position: relative;

    .icon-read-eye {
        position: relative;
        top: 3px;
    }

    &.message-info-out {
        /* position: absolute; */
        /* bottom: 11px; */
        /* right: 27px; */
        color: #ffffff;
        text-align: right;
    
        .centered-dot {
          width: 2px;
          height: 2px;
          display: inline-block;
          vertical-align: top;
          background: #ffffff;
          border-radius: 50%;
          position: relative;
          top: 9px;
        }

        &.message-info-dark {
            background: rgba(25, 25, 25, 0.6);
            padding: 2px 5px !important;
            color: #ffffff !important;
            bottom: 16px;
            right: 16px;
            position: absolute !important;
            width: unset !important;
            border-radius: 8px;
        
            .centered-dot {
              background: #ffffff;
            }
        }

        &.message-info-in-media {
            padding: 0 10px 10px;
        }
    
        img {
            margin-left: 4px;
            width: 16px;
            position: relative;
            /* top: 4px; */
        }
    }

    &.message-info-out-media {
        border-radius: 10px;
        padding: 2px 10px 10px;
    }

    &.message-info-in {
        /* position: absolute; */
        /* bottom: 11px; */
        /* right: 10px; */
        color: rgba(25, 25, 25, 0.4);
        /* width: 24px; */
        text-align: right;
        /* width: 26px; */
      
        .centered-dot {
          width: 2px;
          height: 2px;
          display: inline-block;
          vertical-align: top;
          background: rgba(25, 25, 25, 0.4);
          border-radius: 50%;
          position: relative;
          top: 9px;
        }

        &.message-info-dark {
            background: rgba(25, 25, 25, 0.6);
            padding: 2px 5px !important;
            color: #ffffff !important;
            bottom: 16px;
            right: 16px;
            position: absolute !important;
            width: unset !important;
            border-radius: 8px;

            .centered-dot {
              background: #ffffff;
            }
        }

        &.message-info-in-media {
            padding: 0 10px 10px;
        }
    }

    .icon-star-message-info {
        position: relative;
        margin-right: 4px;
        width: 12px !important;
        top: -1px;
    }

    .icon-pin-message-info-out {
        position: relative;
        margin-right: 2px !important;
        margin-left: 0 !important;
        width: 12px !important;
        top: -1px;
    }

    .icon-pin-message-info-in {
        position: relative;
        margin-left: 4px;
        width: 12px !important;
        top: -1px;
    }
}
