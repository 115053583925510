.room-data-link-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 0px 10px;
    cursor: pointer;

    .room-data-link-icon-wrapper {
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 8px;
        margin-left: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--main-dark-orange);

        img {
            width: 12px;
            height: 12px;
            color: white;
        }
    }
    
    .room-data-link-icon {
        // width: 12px;
        height: 12px;
        color: white;
    }

    .room-data-link-label {
        width: 100%;
        height: 24px;
        margin-left: 8px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.4px;
        font-style: bold;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--main-dark-orange);
    }

    .room-data-link-separator {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 1px;
        background-color: #E8E8E8;
    }

    .room-data-link-clickable {
        position:absolute; 
        width: calc(100% - 40px);
        height: 100%;
        top: 0px;
        left: 0px;
        right: 40px;
        z-index: 1;
    }

    .hover-dropdown-menu-wrapper {
        position: absolute;
        display: none;
        top: 50%;
        transform: translate(0, -50%);
        right: 16px;
        z-index: 2;
        width: 24px;
        height: 24px;
    }

    .hover-dropdown-menu-wrapper .dropdown .dropdown-menu .dropdown-item .action-icon {
        top: 0px;
        left: 0px;
    }
}

.room-data-link-wrapper:hover {

    .room-data-link-label {
        margin-right: 48px;
    }

    .hover-dropdown-menu-wrapper {
        display: block !important;
    }
}
