.chat-room-header-pin-message-container {
    height: 48px;
    width: 100%;
    position: absolute;
    background: #fff;
    left: 0;
    top: 52px;
    border-top: solid 1px #dcdcdc;
    padding: 8px 60px 8px 18px;

    &.pinned-message-top-hide {
        display: none;
    }

    .pinned-message-counter-bar-wrapper {
        width: 2px;
        height: 32px;
        background: #fff;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) scale(-1);

        &.pinned-bar-1 {
            div {
                height: 100%;
            }
        }

        &.pinned-bar-2 {
            div {
                height: calc(50% - 1px);
            }   
        }

        &.pinned-bar-3 {
            div {
                height: calc((100% / 3) - 1px);
            }
        }

        &.pinned-bar-4 {
            div {
                height: calc(25% - 1px);
            }
        }

        div {
            margin-bottom: 2px;
            background: #FFCB99;
            width: 2px;

            &:nth-last-child(1) {
                margin: 0;
            }

            &.active {
                background: #ff7d00;
            }

            &.infinite-active {
                background: #FFF2E5;
            }
        }
    }

    .pinned-message-text-wrapper {
        width: 100%;
        // background: lightblue;
        text-align: left;
        padding: 0 0 0 12px;
        cursor: pointer;

        .pinned-title {
            font-size: 12px;
            letter-spacing: -0.4px;
            color: #ff7d00;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
            margin: 0;
        }

        .pinned-body {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: #191919;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
            margin: 0;
        }
    }

    .right-pinned-icon {
        object-fit: unset;
        width: 30px;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 0;
        cursor: pointer;
    }
}