.my-account-modal {
    .modal-header {
        background: #ffffff;
        position: relative;
        text-align: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 15px;
        // height: 53px;
        height: auto;
        overflow: hidden;
        // width: calc(100% - 17px);
        width: calc(100%);

        .close-my-account {
            font-size: 30px;
            position: absolute;
            top: 214px;
            right: 5%;
            cursor: pointer;
            transition: ease-in-out .2s;

            &:hover {
                color: #ff7d00;
            }
        }

        .modal-title {
            margin: 0;
            position: relative;
            width: 100%;
        }

        .my-account-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
            line-height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-top-title {
            height: auto;
            width: 100%;
            overflow: hidden;
            display: block;
            text-align: center;
            padding: 0 70px;
            white-space: nowrap;
            // justify-content: space-between;
            // align-items: center;

            p {
                margin: 0 !important;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            svg {
                width: 32px;
                height: 32px;
                cursor: pointer;
                color: var(--main-orange);
                // flex-shrink: 0;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);

                &:hover {
                    color: var(--main-darker-orange);
                }

                &.close-modal-account-button {
                    right: 0;
                    left: unset !important;
                }
            }

            .edit-mode-button {
                margin: 0;
                color: var(--main-orange);
                cursor: pointer;
                font-family: "PTRootUI", sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.5px;
                margin-left: 5px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(0, -50%);

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }
    }

    .modal-content {
        width: 435px;
        border-radius: 8px;
        max-height: calc(100vh - 40px);
        height: 100%;
        overflow: hidden;
        background-color: #f3f3f3;

        .modal-room-info-content {
            left: -500px;
            transition: ease-in-out .3s;
            position: relative;
            //margin: 24px 0 0px;
            overflow: hidden;

            &.active-modal-room-info-content {
                left: 0;
            }

            &.active-circle-modal-room-info {
                left: 0;
                margin: 10px 0;
            }
        }

        .shared-media-info {
            width: 100%;
            position: relative;
            padding: 10px 0 20px;
            background: #FFFFFF;
            border-radius: 8px;

            .shared-media-title {
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 2.5px;
                text-transform: uppercase;
                padding-left: 16px;

                color: var(--main-dark-orange);
            }

            .shared-media-list-wrapper {
                padding: 8px 16px 0;
            }
        }
    }

    .modal-body {
        background: #f3f3f3;
        padding: 0px 38px 66px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 93px);
        width: calc(100% + 17px);

        #change-avatar-input {
            display: none;
        }

        .my-account-avatar-wrapper {
            width: 100%;
            position: relative;
            text-align: center;
            padding: 0;
        }

        .my-account-avatar {
            width: 100%;
            // background: #aff2c0;
            color: white;
            display: inline-block;
            line-height: 80px;
            font-size: 80px;

            .no-avatar-placeholder-wrapper {
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                .no-avatar-placeholder {
                    width: 105px;
                    position: absolute;
                    margin-top: -40px;
                    top: 50%;
                    left: 50%;
                    margin-left: -52.5px;
                    text-transform: uppercase;
                }
            }
        }

        .upload-avatar-progress-wrapper {
            width: 80px;
            height: 80px;
            display: inline-block;
            left: 50%;
            transform: translate(-50%, 0);
            position: absolute;
            border-radius: 50%;
            padding: 3px;
            background: #19191966;

            .upload-progress-border {
                border: solid 6px #ffffff;
                width: 100%;
                height: 100%;
                border-radius: 50%;

                svg {
                    font-size: 35px;
                    color: #ffffff;
                    position: relative;
                    top: 14px;
                    cursor: pointer;
                    transition: ease-in-out .1s;

                    &:hover {
                        color: #efefef;
                    }
                }
            }
        }

        .avatar-uploading-wrapper {
            color: rgba(25, 25, 25, 0.4);
            letter-spacing: -0.5px;
            font-size: 16px;

            .lds-ring {
                position: relative;
                top: 4px;
                margin-right: 10px;

                div {
                    border-color: #9b9b9b transparent transparent transparent;
                }
            }
        }

        .my-profile-optional {
            color: rgba(25, 25, 25, 0.4);
            font-size: 14px;
            letter-spacing: -0.2px;
            display: block;
            margin-top: 3px;
        }

        .change-avatar-button {
            cursor: pointer;
            color: #ff7d00;
            font-size: 16px;
            letter-spacing: -0.5px;
            display: block !important;
            margin: 8px 0 0;
            text-align: center;
            width: 100%;
            position: relative;

            .input-file-wrapper-chat {
                top: 30px;
                right: unset;
                left: 50%;
                margin-left: -110px;
                border-radius: 6px;
                background: #ffffff;

                .input-file-content {
                    padding: 13px 14px;
                    text-align: left;
                    align-items: center;
                    display: flex;
                    background: transparent;
                    svg, img {
                        height: 24px;
                        width: 24px;
                        position: initial;
                        margin-right: 12px;
                    }
                    img:last-of-type {
                        display: none;
                    }

                    img:first-of-type {
                        display: block;
                    }
                    
                    span {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.3px;
                        color: #191919;
                    }
                    &:last-of-type {
                        span {
                            color: var(--main-dark-red);
                        }
                    }
                    &:hover {
                        background-color: var(--main-dark-orange);
                        color: var(--main-darker-orange);
        
                        img:first-of-type {
                            display: none;
                        }
                        img:last-of-type {
                            display: block;
                        }
                        span {
                            color: #ffffff;
                        }
                    }

                    &:nth-child(1) {
                        border-radius: 6px 6px 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 6px 6px;
                    }
                }
            }

            img {
                position: relative;
                top: 5px;
                margin-right: 5px;
            }
        }

        .profile-info-wrapper {
            background-color: transparent;
            padding: initial;
            margin: initial;
            margin: 24px 0 0;
            border-radius: 8px;

            .input-my-account {
                padding-bottom: 24px;
    
                input, .input {
                    padding: 16px;
                    line-height: 1;
                    width: 100%;
                    border-radius: 8px;
                    margin: 8px 0 0;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    word-wrap: break-word;

                    &[disabled] {
                        background: rgba(25, 25, 25, 0.1);
                        border: none;
                    }
                }
    
                .username-rule {
                    display: block;
                    font-size: 14px;
                    line-height: 21px;
                }
    
                &.phone-input-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    label {
                        // color: rgba(25, 25, 25, 0.2);
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        line-height: 21px;
                        flex: 0 0 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-size: 12px;
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 18px;
                            color: #A8A8A8;
                        }
                    }

                    input {
                        width: calc(100% - 107px);
                        display: inline-block;
                        vertical-align: top;
                    }
    
                    .phone-input {
                        color: #191919;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        line-height: 1;
                        padding: 15px 16px 16px;
                        background: rgba(25, 25, 25, 0.1);
                        border: none;
                        width: 90px;
                        display: inline-block;
                        margin-right: 17px;
                        vertical-align: top;
                    }

                    
                }
            }

            &.not-edit {
                background-color: #FFFFFF;
                padding: 0;
    
                .input-my-account {
                    padding: 9px 24px;
                    border-bottom: solid 1px #f6f6f6;
    
                    &.phone-input-wrapper label {
                        font-family: 'PTRootUI', sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        color: #FF7E00;
                    }
                }
    
                label {
                    font-family: 'PTRootUI', sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #FF7E00;
                }
    
                input, .input {
                    padding: 0;
                    line-height: 24px;
                    background: #ffffff;
                    background: transparent;
                    border: none;
                    margin: 4px 0 0;
    
                    &[disabled] {
                        background: #ffffff;
                        background: transparent;
                        border: none;
                    }
                }
            }
        }

        .action-wrapper {
            width: 100%;
            background: #ffffff;
            padding: 0;
            border-radius: 8px;

            .mute-unmute-option {
                position: relative;
                .mute-unmute-option-value {
                    color: rgba(25, 25, 25, 0.6);
                    letter-spacing: -0.3px;
                    font-size: 16px;
                    display: inline-block;
                    position: absolute;
                    right: 38px;

                    svg {
                        color: rgba(25, 25, 25, 0.6);
                        position: absolute;
                        right: -34px;
                    }
                }
            }

            ul {
                margin: 0;
                list-style: none;
                padding: 0;

                li {
                    height: 48px;
                    line-height: 48px;
                    position: relative;
                    border-bottom: solid 1px #dcdcdc;
                    cursor: pointer;
                    padding-left: 42px;

                    &:nth-last-child(1) {
                        border: none;
                    }

                    img {
                        position: absolute;
                        left: 12px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    svg {
                        color: grey;
                        font-size: 25px;
                        position: absolute;
                        right: 12px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    .lds-ring {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, 50%);

                        div {
                            border-color: #ff7e00 transparent transparent transparent;
                        }
                    }

                    &:hover {
                        color: #ff7d00;
                    }

                    &.action-red {
                        color: rgba(255, 63, 87, 1);

                        &:hover {
                            color: #ff0020 !important;
                        }
                    }

                    > div:last-child {
                        position: absolute !important;
                        right: 12px;
                        top: 50%;
                        margin-top: -13px;
                    }
                }
            }
            
            &.bottom-action-wrapper {
                margin: 24px 0;
            }
        }

        .my-accout-action-button {
            color: rgb(255, 125, 0);
            font-size: 16px;
            letter-spacing: -0.5px;
            line-height: 24px;
            padding: 16px;
            border-radius: 8px;
            background: #ffffff;
            margin-top: 8px;
            position: relative;
            cursor: pointer;
            transition: ease-in-out .1s;

            &:hover {
                background: #FFE7D0;
            }

            svg {
                position: absolute;
                right: 3px;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 20px;
            }

            &.logout {
                background-color: var(--main-dark-red);
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;

                .lds-ring {
                    margin-right: 19px;

                    div {
                        border-color: #ffffff transparent transparent transparent;
                    }
                }

                svg {
                    right: 6px;
                }

                img {
                    transform: rotate(180deg);
                    margin-right: 19px;
                }
            }
        }
    }

    .modal-footer {
        background: #ffffff;
        border-top: solid 1px #dcdcdc;
        padding: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        button {
            width: 100%;
            padding: 15px 0;
            font-size: 16px;
            letter-spacing: -0.3px;
            line-height: 1;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        .modal-content {
            width: 100%;
            height: 100%;
            max-height: unset;
            border-radius: 0;

            .modal-body {
                width: 100%;
                max-height: unset;
                height: calc(100vh - 53px);
            }

            .circle-top-background {
                // width: 120vw;
                // left: 50%;
                // transform: translate(-50%, 0);
                // top: -205px;
            }
        }
    }
}

.modal-upload-avatar {
    .modal-content {
        padding: 32px;
        border-radius: 8px;
    }

    .modal-body {
        b {
            color: #191919;
            font-size: 20px;
            letter-spacing: -0.6px;
        }

        p {
            color: rgba(25, 25, 25, 0.8);
            font-size: 16px;
            letter-spacing: -0.3px;
            margin: 8px 0 40px;
        }

        .modal-upload-avatar-button-wrapper {
            width: 100%;
            text-align: right;

            .button-cancel {
                font-size: 16px;
                letter-spacing: -0.5px;
                cursor: pointer;
                border: none;
                width: 85px;
                padding: 8px 0 10px;
                background: none;
                vertical-align: top;

                b {
                    color: rgba(25, 25, 25, 0.6);
                    font-size: 16px;
                }
            }
            
            .button-retry {
                background: rgb(25, 199, 0);
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: -0.5px;
                border: none;
                width: 85px;
                margin-left: 8px;
                padding: 8px 0 10px;
                vertical-align: top;

                b {
                    color: rgb(255, 255, 255);
                    font-size: 16px;
                }
            }

            .button-cancel-red {
                background: #ff3f57;
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: -0.5px;
                border: none;
                width: 125px;
                margin-left: 8px;
                padding: 8px 0 10px;
                vertical-align: top;

                b {
                    color: rgb(255, 255, 255);
                    font-size: 16px;
                }
            }
        }
    }
}