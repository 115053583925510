contact-by-alphabet-wrapper
.goback-new-chat {
    font-size: 25px;
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
    transition: ease-in-out .2s;

    &:hover {
        color: #ff7d00;
    }
}

.new-group-wrapper {
    height: calc(100% - 115px);
    background: #f3f3f3;

    &.new-group-wrapper-chatroom {
        height: calc(100% - 206px);
    }

    .new-group-empty-contact {
        text-align: center;
        padding-top: 92px;
        border-top: solid 1px #dcdcdc;
        
        p {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.8);
            margin: 0 0 8px;
        }

        b {
            font-size: 20px;
            color: #ff7d00;
            letter-spacing: -0.6px;
            cursor: pointer;

            &.new-group-add-contact:hover {
                color: var(--main-darker-orange);
            }
        }
    }

    .new-group-user-not-found {
        text-align: center;
        padding-top: 32px;
        border-top: solid 1px #dcdcdc;

        p {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.8);
            margin: 0 0 8px;
        }

        b {
            font-size: 16px;
            color: #ff7d00;
            letter-spacing: -0.5px;
        }
    }
}

.new-group-contact-list-wrapper {
    position: relative;
    height: 100%;

    .cant-find-contact {
        text-align: center;
        padding-top: 20px;
    }

    .contact-by-alphabet-wrapper {
        position: relative;
        height: 100%;

        .contact-name-wrapper {
            position: relative;

            .user-avatar-wrapper {
                margin: 21px 10px 0 16px;
            }

            img {
                // margin: 20px 10px 0 16px;
            }
    
            label {
                position: absolute !important;
                right: 16px;
                top: 40%;
            }
        }
    }

    .contact-alphabet {
        margin: 10px 30px;
    }

    .contact-name {
        padding: 18px 0;
        position: relative;

        .custom-checkbox {
            position: absolute;
            right: 36px;
            top: 38px;
        }

        p {
            max-width: 100%;
        }
    }

    .contact-username {
        color: #ff7d00;
        font-size: 14px;
        letter-spacing: -0.2px;
        max-width: calc(100% - 46px);
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.new-group-member-list-wrapper {
    width: 100%;
    // height: 217px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);

    .member-list-count-wrapper {
        padding: 24px 28px 12px;
        font-size: 10px;
        letter-spacing: 1.5px;
        color: rgba(25, 25, 25, 0.6);

        // b {
            // color: #ff7d00;
            // letter-spacing: 3px;
            // font-size: 14px;
        // }
    }

    .memberlist-user-wrapper {
        width: 100%;
        // overflow-x: auto;
        max-width: 100%;
        padding: 0;
        white-space: nowrap;
        height: 84px;
        overflow-y: hidden;
        position: relative;

        .memberlist-inner-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            overflow: auto;
        }

        .memberlist-user {
            width: 52px;
            margin-left: 24px;
            position: relative;
            text-align: center;
            display: inline-block;

            .member-list-user-avatar {
                width: 52px;
                height: 52px;
                border-radius: 50%;
                line-height: 56px;

                img {
                    height: 52px;
                    width: 52px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                b {
                    color: #ffffff;
                }
            }

            

            p {
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 10px 0 0;
                font-size: 14px;
                letter-spacing: -0.2px;
            }

            .remove-member-list {
                width: 18px;
                border: solid 2px #ffffff;
                height: 18px;
                background: #ffffff;
                border-radius: 50%;
                position: absolute;
                top: 33px;
                right: 0;
                cursor: pointer;
                
                img {
                    width: 17px;
                    height: 17px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &:nth-last-child(1) {
                margin-right: 24px;
            }
        }
    }

    .memberlist-submit-wrapper {
        padding: 12px;

        button {
            width: 100%;
            font-size: 16px;
            letter-spacing: -0.5px;
            // line-height: 1;
            // padding: 14px 0 16px;
            padding: 12px 0 14px;
        }

        svg {
            position: relative;
            top: 1px;
            font-size: 20px;
            vertical-align: top;
        }
    }
}

.new-group-on-search-wrapper {
    background: #f3f3f3;
    position: relative;
    width: 100%;

    .search-from {
        color: #ff7e00;
        letter-spacing: 2.5px;
        font-size: 12px;
        padding: 12px;
    }

    .contact-name-wrapper {
        background: #ffffff;
        cursor: pointer;
        position: relative;

        .user-avatar-wrapper {
            margin: 21px 10px 0 16px;
        }

        img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            margin: 12px 10px 0 16px;
            display: inline-block;
            vertical-align: top;
        }

        label {
            position: absolute !important;
            right: 16px;
            top: 40%;
        }
    }

    .contact-alphabet {
        margin: 24px 30px 24px;
    }

    .contact-name {
        width: calc(100% - 78px);
        display: inline-block;
        padding: 18px 0;
        position: relative;

        .custom-checkbox {
            position: absolute;
            right: 36px;
            top: 28px;
        }
    }

    .contact-username {
        color: #ff7d00;
        font-size: 14px;
        letter-spacing: -0.2px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}