.login-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, #FFAB3A 0%, #FF7D00 100%);

    &::before {
        content: "";
        background: url('../../assets/img/login-background-left-assets.svg');
        background-repeat: no-repeat;
        background-size: 33vw;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 33.2vw;
        height: 37.1vw;
        z-index: -1;
    }

    &::after {
        content: "";
        background: url('../../assets/img/login-background-right-assets.svg');
        background-repeat: no-repeat;
        background-size: 31.4vw;
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 50%;
        width: 31.4vw;
        height: 40vw;
        z-index: -1;
    }

    .login-main-content {
        padding: 0 32px;
        width: 100%;
        height: 100%;
        text-align: center;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .logo-image {
            margin-top: 80px;
        }

        .subtitle {
            margin: 4px 0 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 4px;
            color: white;
            text-transform: uppercase;
        }

        .login-card {
            padding: 48px;
            margin: auto;
            margin-top: 80px;
            margin-bottom: 80px;
            width: 100%;
            max-width: 540px;
            border-radius: 32px;
            background: #ffffff;
            box-shadow: 0px 2px 20px 0px #1919192E;

            b {
                font-size: 40px;
                letter-spacing: -0.6px;
                color: #191919;
            }

            p {
                margin: 24px 0 0;
                font-size: 16px;
                letter-spacing: -0.3px;
                line-height: 24px;
                color: #191919CC;
            }

            .otp-channel-icon {
                display: inline-block;
                margin: 12px 8px 0 0;
                width: 24px;
                height: 24px;
            }

            .verification-phone-number {
                display: inline-block;
                margin: 12px 0 0;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.6px;
                color: #FF7E00;
            }

            .input-otp-container {
                display: block;
                position: relative;
                margin: 24px 0 0;
                width: 100%;
                height: 36px;

                .input-otp {
                    position: absolute;
                    display: block;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 36px;
                    letter-spacing: 64px;
                    padding: 0 0 0 24px;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 30px;
                    color: #FFFFFF00;
                    caret-color: #FF7E00;
                    border: none;
                    background: none;

                    @media screen and (max-width: 599px) {
                        padding: 0 0 0 4vw;
                        letter-spacing: calc(18vw - 45px);
                    }
                }

                b {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    margin: 0 16px 0 0;
                    width: calc((100% - 80px) / 6);
                    height: 36px;
                    bottom: 2px;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: center;
                    color: #191919CC;
                    border-bottom: 2px solid #19191966;

                    &:nth-last-child(1) {                     
                        margin: 0;
                    }

                    @media screen and (max-width: 375px) {
                        margin: 0 4px 0 0;
                        width: calc((100% - 20px) / 6);
                    }
                }

                .active {
                    border-color: #FF7E00;
                }

                .error {
                    color: #FF3F57;
                    border-color: #FF3F57;
                }
            }

            .did-not-receive-otp {
                display: block;
                margin: 24px 0 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: -0.2px;
                text-align: center;
                color: #191919CC;
            }

            .invalid-otp {
                display: block;
                margin: 24px 0 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: -0.2px;
                text-align: center;
                color: #FF3F57;
            }

            .loading-resend-otp {
                display: block;
                width: 100%;
                text-align: center;

                .lds-ring {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: 8px 4px 0 0;

                    div {
                        margin: 6px 0 0;
                        width: 16px;
                        height: 16px;
                        border-color: #a3a3a3 transparent transparent transparent;
                    }
                }

                b {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    color: #19191966;
                }
            }

            .resend-otp-wrapper {
                display: block;
                width: 100%;
                text-align: center;

                .resend-otp {
                    display: inline-block;
                    margin: 8px 0 0;
                    color: #ff7e00;
                    cursor: pointer;
                    text-align: center;
        
                    &:hover {
                        color: var(--main-darker-orange);
                    }
        
                    img {
                        vertical-align: top;
                        position: relative;
                        width: 20px;
                        height: 20px;
                        top: 2px;
                        // margin-right: 2px;
                    }

                    b {
                        margin: 0 2px;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: -0.5px;
                        color: #FF7E00;
                    }
                }
            }
    
            .wait-otp {
                margin: 8px 0 0;
                font-size: 16px;
                letter-spacing: -0.5px;
                height: 24px;
                font-weight: bold;
                color: #19191966;
                text-align: center;
            }

            .phone-wrapper {
                margin: 24px 0 0;

                .code-phone {
                    width: 90px;
                    display: inline-block;
                    margin-right: 16px;
                }

                .phone-number {
                    width: 100%;
                    display: inline-block;
                    position: relative;
                    border: solid 1px #1919191A;
                    border-radius: 8px;
                    padding: 12px 16px;
                    text-align: left;

                    .phone-input-container {
                        display: inline-block;
                        position: relative;
                        margin-left: 4px;
                        width: calc(100% - 110px);
                        height: 18px;

                        input {
                            position: absolute;
                            top: 2px;
                            left: 0px;
                            padding: 0;
                            width: 100%;
                            font-size: 16px;
                            letter-spacing: 0.5px;
                            color: #FFFFFF00;
                            caret-color: #FF7E00;
                            border-radius: 0;
                            border: none;
                            background: none;
                            // -webkit-box-shadow: 0 0 0 50px white inset;
                            // box-shadow: 0 0 0 50px white inset;
                    
                            &::placeholder {
                                color: #19191966;
                                opacity: 1;
                              }
                              
                            &::-ms-input-placeholder {
                                color: #19191966;
                                opacity: 1;
                            }
                            
                            &::-webkit-input-placeholder {
                                color: #19191966;
                                opacity: 1;
                           }
                        }

                        p {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            margin: 0;
                            font-size: 16px;
                            color: #191919;
                            background: none;
                        }
                        
                        .phone-input-placeholder {
                            color: #19191966;
                        }
                    }

                    &:focus-within {
                        border: solid 1px #ff7e00;
                    }

                    .phone-country {
                        display: inline-block;
                        // position: relative;
                        cursor: pointer;

                        img {
                            display: inline-block;
                            width: 32px;
                            height: 24px;
                            border-radius: 2px;
                        }
                        
                        svg {
                            display: inline-block;
                            margin-left: 4px;
                            transform: translate(0, 4px);
                        }

                        p {
                            display: inline-block;
                            margin: 0 0 0 8px;
                            color: #191919;
                        }
                    }

                    &[disabled] {
                        cursor: pointer;
                    }

                    .lds-ring {
                        width: 32px;
                        height: 16px;
                        transform: translate(8px, 2px);

                        div {
                            width: 16px;
                            height: 16px;
                            border-color: #ff7e00 transparent transparent transparent;
                        }
                    }
                }

                .error {
                    border: solid 1px #FF3F57;
                }

                .loading {
                    border: none;
                    background: #1919191A;
                    color: #19191966;

                    .phone-input-container {

                        // input {
                        //     color: #19191966;
                        //     box-shadow: 0 0 0 50px #E8E8E8 inset;
                        //     -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
                        // }

                        p {
                            color: #19191966;
                        }
                    }

                    .phone-country {
    
                        p {
                            color: #19191966;
                        }
                    }
                }
            }

            .input-error-info {
              display: block;
              margin-top: 8px;
              width: 100%;
              height: 18px;
              color: #FF3F57;
              text-align: left;
            
              svg {
                display: inline-block;
                vertical-align: top;
                margin-right: 4px;
                height: 16px;
              }
            
              b {
                display: inline-block;
                vertical-align: top;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.4px;
                color: #FF3F57;
                transform: translateY(-1px);
              }
            }

            button {
                width: 100%;
                padding: 14px 32px;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.5px;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }

                .lds-ring {
                    width: 16px;
                    height: 16px;
                    margin-right: 6px;
                    transform: translateY(2px);

                    div {
                        width: 16px;
                        height: 16px;
                        border-color: #A3A3A3 transparent transparent transparent;
                    }
                }
            }
            
            .no-fill-orange-border-button {
                margin-top: 12px;
            }
            
            .no-fill-orange-border-button-loading {
                img {
                    filter: grayscale(100%);
                }
            }

            .qr-code {
                display: block;
                margin: auto;
                margin-top: 12px;
                height: 150px;
                transition: ease-in-out .1s;
            }
        }

        .left {
            text-align: left;
            color: #19191999;

            p {
                margin: 12px 0 0;
            }
        }

        .verification-status-wrapper {
            padding: 48px;
            margin: auto;
            margin-top: 80px;
            margin-bottom: 80px;
            width: 100%;
            max-width: 540px;
            border-radius: 32px;
            background: #ffffff;
            box-shadow: 0px 2px 20px 0px #1919192E;

            .verification-status-loading {
                display: block;
                position: relative;
                width: 88px;
                height: 88px;
                left: 50%;
                transform: translateX(-50%);
                margin-bottom: 16px;

                div {
                    width: 72px;
                    height: 72px;
                    border-width: 8px;
                    top: 8px;
                    left: 8px;
                    border-color: #FF7E00 transparent transparent transparent;
                }
            }

            .verification-status-failed {
                padding: 9.5px;
                margin: auto;
                width: 88px;
                height: 88px;
                background: #FF3F5733;
                border-radius: 44px;
            
                svg {
                    padding: 12.5px;
                    width: 69px;
                    height: 69px;
                    background: #FF3F57;
                    color: white;
                    border-radius: 35px;
                }
            }
      
            .verification-status-success {
                padding: 9.5px;
                margin: auto;
                width: 88px;
                height: 88px;
                background: #11830033;
                border-radius: 44px;
            
                svg {
                    padding: 12.5px;
                    width: 69px;
                    height: 69px;
                    background: #118300;
                    color: #cfe6cc;
                    border-radius: 35px;
                }
            }

            .verification-status-title {
                display: block;
                margin-top: 8px;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -0.6px;
                text-align: center;
            }

            .verification-status-description {
                display: block;
                margin: 12px 0 0;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.3px;
                text-align: center;
                white-space: pre-line;
            }

            .redirect {
                margin: 32px 0 0;
            }
        }

        .dont-have-account {
            margin-top: 16px !important;

            b {
                font-size: 16px;
                letter-spacing: -.2px;
                color: #191919;
                text-align: center;
                color: rgba(25,25,25,.5);
            }

            a {
                color: #ff7d00;

                &:hover {
                    color: #E87200;
                }
            }
        }

        .label-button-wrapper {
            display: block;
            width: 100%;
            text-align: center;

            .label-button {
                display: inline-block;
                padding: 3px;
                margin: auto;
                margin-top: 12px;
                text-align: center;
                color: #FF7E00;
                cursor: pointer;
    
                &:hover {
                    color: var(--main-darker-orange);
                }

                svg {
                    margin: 0 2px;
                    width: 12px;
                    height: 12px;
                    transform: translateY(4.5px);
                }

                b {
                    margin: 0 2px;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    color: #FF7E00;
                }
            }
        }
    }
}

.loading-modal {
    .modal-content {
        padding: 32px;
        width: 224px;
        border-radius: 32px;
        background: #ffffff;
        text-align: center;
        box-shadow: 0px 3px 22px 0px #1919194D;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.6px;
        font-weight: bold;
        text-align: center;
        color: #191919;

        .loading {
            display: block;
            position: relative;
            width: 88px;
            height: 88px;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 16px;

            div {
                width: 72px;
                height: 72px;
                border-width: 8px;
                top: 8px;
                left: 8px;
                border-color: #FF7E00 transparent transparent transparent;
            }
        }
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .login-main-content {
        padding-top: 34px;
    }
}
