.modal-blocking-screen-loading-notext {
    .modal-content {
        box-sizing: border-box;
        min-height: 128px;
        width: 128px;
        padding: 16px;
        border-radius: 8px;
        // Hide container background
        background: none !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.modal-blocking-screen-loading-text {
    .modal-content {
        box-sizing: border-box;
        min-height: 128px;
        width: 25%;
        padding: 16px;
        border-radius: 8px;
    }
}

.loading-image {
    height: 56px;
    width: 56px;
    margin: auto;
    position: relative;
    border-radius: 50%;
    margin-bottom: 12px;

    .lds-ring {
        height: 56px;
        width: 56px;

        div {
            height: 56px;
            width: 56px;
            border: 6px solid;
            border-color: #ff7d00 transparent transparent transparent
        }
    }
}

.loading-text {
    width: 100%;
    margin: auto;
    font-size: 16px;
    font-family: "PTRootBold";
    text-align: center;
    color: var(--main-dark-orange);
    word-break: break-word;
}
