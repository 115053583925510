.modal-max-pin {
    .modal-content {
        width: 300px;
        font-size: 16px;
        letter-spacing: -0.5px;
        border-radius: 8px;

        .modal-body {
            padding: 16px 24px;
            overflow: hidden;

            .modal-max-pin-title {
                font-size: 16px;
                letter-spacing: -0.5px;
                margin: 0 0 4px 0;
            }

            .modal-max-pin-desc {
                color: rgba(25, 25, 25, 1);
                font-size: 16px;
                letter-spacing: -0.5px;
                line-height: 21px;
                text-align: center;
            }

            button {
                width: 100%;
            }
        }
    }
}