.multiple-forward-input-wrapper {
    width: 100%;
    height: 56px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    .cancel-multiple-forward {
        font-size: 14px;
        cursor: pointer;
        letter-spacing: -0.4px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
        color: #ff7d00;

        &:hover {
            color: #E87200;
        }
    }

    .selected-forward-counter {
        font-size: 14px;
        letter-spacing: -0.4px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #191919;
    }

    .icon-submit-multiple-forward {
        font-size: 26px;
        cursor: pointer;
        letter-spacing: -0.4px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
        color: #ff7d00;

        &:hover {
            color: #E87200;
        }
    }
}