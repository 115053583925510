.chat-room-wrapper {
    background: #F3F3F3;
    width: calc(100% - 435px);
    max-width: calc(100% - 435px);
    display: inline-block;
    height: 100%;
    max-height: 100vh;
    vertical-align: top;
    // transition: ease-in-out .5s;

    &.no-chat-action {
        .message-action-wrapper-new {
            display: none !important;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 1024px) {
        position: fixed !important;
        max-width: unset;
        top: 0;
        right: -100%;
        height: 100%;
        width: 100%;
        z-index: 1;

        &.active-chat-room {
            right: 0;
        }

        @media screen and (max-width: 767px) {
            .message-action-wrapper {
                display: none !important;
            }
        }
    }
}

.voice-play-pause-button {
    display: none;

    &.active-voice-play-pause-button {
        display: block !important;
    }
}