.room-modal-shared-media {
    position: relative;
    width: 100% !important;
    max-height: calc(100% - 58px) !important;
    overflow-y: auto !important;
    padding: 0 0 20px !important;

    .shared-media-container {
        width: 100%;
        position: relative;
        padding: 12px 0 24px;
        background: #F3F3F3;

        .shared-media-tab-container {
            position: sticky;
            position: -webkit-sticky;
            top: -1px;
            width: calc(100% - 76px);
            height: 38px;
            line-height: 38px;
            margin-left: 38px;
            margin-right: 38px;
            text-align: center;
            align-items: center;
            z-index: 3;
            background: #FFFFFF;

            .shared-media-tab-buttons-wrapper {
                margin: auto;
                width: 100%;
            }
        }
    }

    /*
    ===================================================
    */

    .room-modal-shared-media-wrapper {
        width: 100%;
        height: 1px;
        position: relative;
        padding: 0 24px 16px;
        text-align: center;
        
        div {
            border-top: solid 1px #dcdcdc;
            display: inline-block;
            width: 100%;
        }
    }

    .no-shared-media-container {
        width: 100%;
        text-align: center;
        padding: 40px 60px 0px;

        .no-shared-media-img {
            position: absolute;
            left: 50%;
            transform: translate(0, -50%);
            margin: 44px 0 26px;
        }

        .no-shared-media-title {
            margin: 0;
            width: 100%;
            margin-top: 18px;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            letter-spacing: -0.6px;
            color: #191919;
        }

        .no-shared-media-desc {
            margin: 12px 0 0;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.3px;
        }
    }

    .message-action-wrapper-new {
        display: none !important;
    }

    .loading-message-wrapper {
        padding-top: 16px;
        text-align: center;
        width: 100%;

        .lds-ring {
            div {
                border-color: #ff7d00 transparent transparent transparent;
            }
        }
    }
}
