.room-list-no-chat-container {
    padding: 130px 16px 30px 16px;
    text-align: center;

    p {
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 24px;
        margin: 5px 0;
    }

    b {
        font-size: 20px;
        line-height: 24px;

        &:nth-child(3) {
            color: #ff7d00;
        }
    }
}