.shared-media-monthly-item-list-container {
    width: calc(100% - 76px);
    margin-left: 38px;
    margin-right: 38px;
    margin-top: 1px;
    margin-bottom: 3px;
    align-items: center;
    background: #FFFFFF;

    .shared-media-month-separator-container {
        width: 100%;
        position: relative;
        height: 32px;
        line-height: 32px;
        padding: 0px 24px 0;
        text-align: left;

        .shared-media-month-separator-label {
            height: 100%;
            font-style: bold;
            font-size: 14px;
            line-height: 21px;
            color: #191919;
        }
    }
    
    .shared-media-month-separator-line {
        width: 100%;
        height: 1px;
        background-color: #E8E8E8;
    }

    .shared-media-monthly-item-list-wrapper {
        padding: 8px 16px 4px;
    }
}
